module.exports = {
    url: "/archive/student-life/special-events/speakers",
    header: "Speakers",
    description: "Howard Kennedy Elementary has always welcomed guests with open arms--especially when they can teach students new and exciting things! These objects tell us about who some of those guests have been over time.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1904.5.27.civilwarveteranspeaker.pdf",
            label: "This notice from 1904 lists all the Civil War veterans that would be speaking at schools in Omaha for Memorial Day in 1904. Fred Swartzlander would be speaking at the Omaha View School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/M_Burroughs02.png",
            label: "This is a photograph of Margaret Burroughs speaking to a fourth grade class at Howard Kennedy in the 1970s. Margaret Burroughs was a poet, artist, author, and teacher who founded the DuSable Museum of African American History in Chicago. On the right side of the photo is Charles Washington, a former student at Kennedy who had grown up to become a journalist and civil rights activist in North Omaha. On the left side of the photo is Denise King, who was a fourth grade teacher at the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1981.2.28.careers.pdf",
            label: "This clipping from 1981 contains a photograph of Lindsey Miller-Lerman and a number of students. Miller-Lerman came to speak to the children about her career as a lawyer."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1983.2.23.lifeskills.pdf",
            label: "This clipping from 1983 shows a picture of a dental student named Bruce Cooper teaching a third grader, Jill Blacketer, about brushing her teeth."
        },
        {
            pdf: "Kennedy Archives/AllMedia/Kennedy Speaker0001.pdf",
            label: "This is a photograph of a man behind a podium speaking at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2004.11.23.babajamalvisit.pdf",
            label: "This article from 2004 is about Baba Jamal Koram, a storyteller who visited several schools in 2004, including Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2010.10.8.authorvisit.pdf",
            label: "In this clipping from 2010, the Omaha Star describes author Mike Thaler's visit to Kennedy. Thaler is the author of the Black Lagoon series of children's books."
        }
    ]
}
