import React from 'react';
import { withRouter, Link } from "react-router-dom";
import styled from 'styled-components';
import Database from 'src/database';
import Section from 'src/Components/Layout/Section/index.js';

const SearchContainer = styled.div``;

const Result = styled.div`
  margin-bottom: 15px;
`;

function Search({history}) {
  const search = history.location.search.replace('?', '')

  let searchData = (url, needle, haystack, found = []) => {
    Object.keys(haystack).forEach((key) => {
      if (key !== 'pdf' &&
          key !== 'img' &&
          key !== 'name' &&
          key !== 'url' &&
          haystack && 
          haystack[key] && 
          typeof haystack[key].match === 'function' ) 
      {
        if(haystack[key].match(new RegExp(needle,"gi"))){
          found.push({ text: haystack[key], url: url });
          return found;
        }
      }

      if(typeof haystack[key] === 'object'){
        searchData(url, needle, haystack[key], found);
      }
    });

    return found;
  };

  function unique(arr) {
    var a = arr.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

  const getResults = function() {
    let allResults = [];

    Database.database.forEach(data => {
      const results = searchData(data.url, search, data);
      allResults = unique(allResults.concat(results));

      return 1;
    });

    return allResults.map(res => {
      return <Link key={`${res.text}-${res.url}`} to={res.url}>
        <Result>
          <b>{res.url}</b>
          <div>{res.text}</div>
        </Result>
      </Link>
    });
  }

  return (
    <SearchContainer>
      <Section>
        <h1>Search Results</h1>
        {/* Search: {search}
        <hr />
        {JSON.stringify(Database.database)} */}
        {getResults()}
      </Section>
    </SearchContainer>
  );
}

export default withRouter(Search);
