module.exports = {
    url: "/notable-figures/malcolm-x",
    header: "Malcolm X: Human Rights Activist, Muslim Minister, Speaker",
    labels: [
        {
            pdf: "/Kennedy Archives/Notable Figures/MX/1979.6.23.Malcolm.RobertRoseTeacher.pdf",
            label: "In this article from 1979, Rowena Moore speaks about meeting Malcolm X and finding out that she owned the land where his house used to stand."
        },
        {
            pdf: "/Kennedy Archives/Notable Figures/MX/1990.5.17.Washington,Charles.pdf",
            label: "This article from 1990 is about Charles Washington's 1964 interview with Malcolm X."
        }
    ]
}
