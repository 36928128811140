module.exports = {
    url: "/archive/student-life/student-activities/day-to-day/class-activities",
    header: "Class Activities",
    description: "Anyone who attends Howard Kennedy today knows that the teachers there are always looking for new ways to make learning fun. These articles show that Kennedy's teachers have been doing this for years!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1917.2.17.manualtraining.pdf",
            label: "This article from 1917 describes the sewing lessons that the girls at Howard Kennedy took twice a week. Can you imagine sewing being a special at Kennedy again?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1923.6.24.classroomactivity.pdf",
            label: "This article from 1923 talks about a booklet put together by the eighth graders at Howard Kennedy. The booklet was all about Omaha!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1930.1.28.dancingclass.pdf",
            label: "This short notice from 1930 announces that the gym teacher at Kennedy had decided to start a dance class at the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1940.2.18.classroomactivity.pdf",
            label: "This short notice from 1940 states that second and third graders at Kennedy were making models of Omaha buildings."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1949.5.18.manualtraining.pdf",
            label: "In this clipping from 1949, the Youth Activities Director at the Omaha World Herald asks the community to help the boys and girls at Howard Kennedy buy a sewing machine. They were already sewing by hand, but they could do more with the machine."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1951.9.14.naptime.pdf",
            label: "This clipping from 1951 shows a photograph of a class of kindergarteners getting ready to take their daily nap. You can see their teachers behind them!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.1.19.classroomactivity.pdf",
            label: "This article from 1958 describes an activity that the eighth graders at Howard Kennedy were doing, where they studied an entire section from the Omaha World Herald."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.1.22.classroomactivity.pdf",
            label: "In this article from 1958, a reporter explains that the eighth graders at Howard Kennedy created their own newspaper based on a section of the Omaha World Herald that they were studying. There is a picture of some of the students presenting and answering questions about their own articles!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1966.1.27.balloonactivity.pdf",
            label: "This clipping from 1966 contains a photograph of a second grade class at Kennedy as they released a balloon into the air. They placed a message inside the balloon asking for whoever found it to write them back!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1966.3.25.balloonactivity.pdf",
            label: "This notice from 1966 states that one of the balloons released by a second grade class at Kennedy had been found two months later in Silver City, Iowa."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1966.4.8.balloonactivity.pdf",
            label: "This is another notice from 1966 announcing that one of the ballons released by a second grade class at Kennedy had been found two months later in Silver City, Iowa."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1973.5.10.writing.pdf",
            label: "This article from 1973 shows a photograph of a group of sixth grade students at Howard Kennedy who wrote and printed their own newspaper."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1973.12.20.lifeskills.pdf",
            label: "This article from 1973 talks about a guest speaker who came to a sixth grade class at Kennedy and spoke to the students about how to interview for a job."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.1.31.hawaii.pdf",
            label: "In this article from 1974, a second grade class at Howard Kennedy celebrates Hawaiian culture as part of a unit for their social studies class."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.10.24.penpalmeetup.pdf",
            label: "This clipping from 1974 shows pictures of students and faculty from both Howard Kennedy and Florence at a get-together. The students from both schools had been penpals--writing back and forth for months--and they were finally meeting!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1984.1.26.4H.pdf",
            label: "This notice from 1984 announces that 4-H was bringing eggs to classrooms at Kennedy and Conestoga so that students could take care of them and watch them hatch into chicks! 4-H is an organization that works to give children across America hands-on learning experiences."
        },
    ]
}
