module.exports = {
    url: "/archive/student-life/student-activities/after-school",
    header: "After School",
    description: "Often, students at Kennedy keep learning even when the school day is over! Here are a few articles about programs that have been available to kids on school grounds once their classes were finished.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1990.8.8.kidsclub.pdf",
            label: "In this article, a reporter states that Kids Club may be coming to Kennedy. In 1990, Kids Club was a pretty new program, but people already loved it. Can you imagine Howard Kennedy without Kids Club?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1990.12.13.kidsclub.pdf",
            label: "This article from 1990 announces that Howard Kennedy will be the ninth school in the district to have Kids Club to serve students before and after school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2018.8.7.wifibus.pdf",
            label: "This article from 2018 talks about the wifi bus that would soon be brought to Kennedy to provide wifi and tablets for students who climbed aboard."
        }
    ]
}
