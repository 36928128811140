import React from 'react';
import styled from 'styled-components'

const ParagraphsContainer = styled.div`
    margin-bottom: 60px;
`;

const Paragraph = styled.p`
    font-size: ${props => `${props.fontSize}px` || "14px"};
`;

function Paragraphs({ paragraphs, size = 'md' }) {
    let fontSize = '';

    switch(size) {
        case 'sm':
            fontSize = '14';
            break;
        case 'md':
            fontSize = '16';
            break;
        case 'lg':
            fontSize = '18';
            break;
        default:
            fontSize = '16';
            break;
    }

    function createMarkup(value) {
        return {__html: value};
    }

    function getParagraphs() {
        return paragraphs.map((item, index) => {
            return <Paragraph fontSize={fontSize} key={`paragraph-${index}`} dangerouslySetInnerHTML={createMarkup(item)}></Paragraph>
        });
    }

  return (
    <ParagraphsContainer>
        {getParagraphs()}
    </ParagraphsContainer>
  );
}

export default Paragraphs;
