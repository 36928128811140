module.exports = {
    url: "/archive/student-life/special-events/holidays",
    header: "Holidays",
    description: "Tiger Nation LOVES a good party! This section shows the different ways that students at Kennedy have celebrated holidays over the years!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1910.4.23.ArborDay.pdf",
            label: "This article is about all of the ways people in Omaha celebrated Arbor Day in 1910. The students at the new Howard Kennedy school celebrated by planting trees!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1928.12.22.christmasprograms.pdf",
            label: "This clipping describes the different holiday programs put on by schools in Omaha in 1928. At Kennedy, there was a different program in each classroom!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1932.12.22.christmasprograms.pdf",
            label: "In this article, a reporter describes the Christmas celebration at Howard Kennedy in 1932. The students went on a parade from classroom to classroom to see the displays that each grade had prepared."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1943.12.19.christmasprogram.pdf",
            label: "This clipping is about Christmas performances held by different schools in Omaha in 1943. At Kennedy, students put on a play called “Faith, Hope, Charity.”"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.2.11.lincolnsbirthday.pdf",
            label: "This notice from 1948 states that Howard Kennedy would receive an American flag from the Daughters of the Union Veterans of the Civil War in honor of Abraham Lincoln's birthday."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1972.1.15.mlkday.pdf",
            label: "This clipping describes a memorial program held at Kennedy in honor of Martin Luther King, Jr. in 1972. Martin Luther King Day would not become a federal holiday until fourteen years later in 1986."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1972.6.30.independenceday.pdf",
            label: "This clipping from 1972 shows a photograph of kindergarteners at Kennedy celebrating Independence Day a few days early."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.10.10.schoolbirthdaycelebration.pdf",
            label: "This clipping contains a few photographs from a program that celebrated the birthday of Howard Kennedy Elementary School in 1974."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.11.27.halloweenparty.pdf",
            label: "This article from 1974 is about a Halloween Party held for Howard Kennedy fourth graders."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2005.12.22.holidaygifts.pdf",
            label: "In this clipping from the Omaha Star, a reporter explains that two organizations came together to provide all the students at Howard Kennedy with Christmas presents during the 2005 holiday season."
        },
    ]
}
