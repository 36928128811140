module.exports = {
    url: "/archive/old-school-to-new-school/howard-kennedy-changes",
    header: "Howard Kennedy Changes",
    description: "A lot has changed since the building known as Howard Kennedy Elementary was opened in 1910. From having an addition built in the 1950s, to asbestos removal in the 1980s, to a renovation in 2004, the building has lived through over 100 years of care and changes. This part of the archive addresses these changes.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1916.9.15.overcrowding.pdf",
            label: "In this clipping from 1916, it is clear that just six years after the school was opened, Howard Kennedy was already dealing with overcrowding. The school had more students than it could teach at once. This article describes the principal holding class in one of the hallways."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1917.1.26.overcrowding.pdf",
            label: "This article from 1917 continues to discuss the overcrowding problem at Kennedy. Apparently, the school had more students than it had seats!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.1.12.overcrowding.pdf",
            label: "In this article from 1954, the superintendant, Dr. Burke, says that Howard Kennedy is the worst example of overcrowding in the district. The article states that more and more children are being born every year, which means that more kids show up at school. This period of time in which so many American kids were being born after World War II is called the Baby Boom."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.5.4.overcrowding.pdf",
            label: "This notice from 1954 reports that Howard Kennedy was continuing to take on more students than the building could hold. The younger grades had to take turns going to school!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.5.18.architecthired.pdf",
            label: "This article from 1954 announces that the school board has hired an architect to design an addition for Howard Kennedy. An architect is someone who designs buildings."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.10.19.propertyforadditionpurchased.pdf",
            label: "This notice from from 1954 announces that land next to Kennedy was bought so that the addition could be built directly onto the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1955.5.17.overcrowding.pdf",
            label: "In this article from 1955, a reporter writes that Howard Kennedy would get two portable classrooms to use until the new addition was finished."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1955.12.20.additiondetails.pdf",
            label: "This notice from 1955 gives more details about the plans for the new addition to Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1956.12.4.kennedyadditioncontracts.pdf",
            label: "In this article from 1956, it is announced that the school board has decided which company will build the new addition and who will set up the electricity and plumbing."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1956.12.30.kennedyaddition.pdf",
            label: "This clipping from 1956 has a photograph of the construction being done to build the new addition for Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957%20Kennedy%20Addition%2019570001.jpg",
            label: "This is a picture from 1957 of the construction being done to build the addition for Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957%20Kennedy%20Building0001.jpg",
            label: "This is a picture of the addition to Kennedy once it was finished in 1957."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957.9.24.additiondelay.pdf",
            label: "This clipping from 1957 states that the construction on the addition was delayed. This meant that the addition would take at least an extra month to be usable."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957.12.2.additionopening.pdf",
            label: "This is the Omaha World Herald's article on the opening of the new addition at Howard Kennedy in 1957. There are many pictures of the first day the students were able to enter the new part of the building."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957.12.13.additionopening.pdf",
            label: "This is the Omaha Star's article on the opening of the new addition at Howard Kennedy in 1957."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.10.31.additiondedication.pdf",
            label: "This notice from 1958 announces the date and time of the dedication ceremony for the new addition. It also gives the planned order for the events during the program."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1986.7.17.asbestosremoval.pdf",
            label: "In this article from 1986, a reporter writes that the district is having asbestos removed from many school buildings, including Howard Kennedy. Asbestos was used in constructing buildings before the 1970s and can be very dangerous to breathe in."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2004.9.23.renovationdedication.pdf",
            label: "This notice from 2004 announces the date and time of the dedication ceremony for the 2004 renovation of Howard Kennedy."
        }
]
}
