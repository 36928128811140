module.exports = {
    url: '/archive',
    name: 'Archive',
    header: "This is a digital archive!",
    paragraphs: [
        'This digital archive is full of photographs and newspaper clippings that can teach us about the history of Howard Kennedy, its students, and its staff. Each clipping or photograph has a label that explains a little bit about it, but there are many more exciting stories to find if you look more closely at the objects. So start clicking, scrolling, and reading, and take a peek into the past!',
        '<a href="/what-is-an-archive">Click here to learn more about what archives are and how we can use them!</a>'
    ],
    categories: [
        { name: 'alumni', img: '/Kennedy Archives/Pictures for Pages/Alumni.png' },
        { name: 'faculty-and-staff', img: '/Kennedy Archives/Pictures for Pages/Faculty and staff.png' },
        { name: 'featured-columns', img: '/Kennedy Archives/Pictures for Pages/Featured Columns.jpg' },
        { name: 'old-school-to-new-school', img: '/Kennedy Archives/Pictures for Pages/Old School to New School.jpg' },
        { name: 'student-life', img: '/Kennedy Archives/Pictures for Pages/Student Life.jpg' }
    ],
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1889.6.16.notesonschool X.pdf",
            label: "This is an example label. This is actually in the wrong area. This PDF goes in Student Life -> School News"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.6.6.hkschoolnews.pdf",
            label: "Another label"
        }
    ]
}
