export const routes = {
    HOME: '/',

    ABOUT: '/about',
    
    CONTACT: '/contact',

    SEARCH: '/search',

    WHAT_ARCHIVE: '/what-is-an-archive',
    
    NOTABLE_FIGURES: '/notable-figures',
    NOTABLE_FIGURES_HOWARD_KENNEDY: '/notable-figures/howard-kennedy',
    NOTABLE_FIGURES_MALCOLM_X: '/notable-figures/malcolm-x',
    NOTABLE_FIGURES_MILDRED_BROWN: '/notable-figures/mildred-brown',

    ARCHIVE: '/archive',
    
    ARCHIVE_ALUMNI: '/archive/alumni',
    
    ARCHIVE_FACULTY_AND_STAFF: '/archive/faculty-and-staff',

    ARCHIVE_OLD_SCHOOL: '/archive/old-school-to-new-school',
    ARCHIVE_OLD_SCHOOL_FUTURE: '/archive/old-school-to-new-school/future-of-kennedy',
    ARCHIVE_OLD_SCHOOL_CHANGES: '/archive/old-school-to-new-school/howard-kennedy-changes',
    ARCHIVE_OLD_SCHOOL_OPENING: '/archive/old-school-to-new-school/howard-kennedy-opening',
    ARCHIVE_OLD_SCHOOL_DISREPAIR: '/archive/old-school-to-new-school/omaha-view-disrepair',

    ARCHIVE_FC: '/archive/featured-columns',
    ARCHIVE_FC_STUDENT_LETTERS: '/archive/featured-columns/student-letters',
    ARCHIVE_FC_ANASTASIAS_ALMANAC: '/archive/featured-columns/anastasias-almanac',
    ARCHIVE_FC_FAMILY_OF_THE_WEEK: '/archive/featured-columns/family-of-the-week',

    ARCHIVE_SL: '/archive/student-life',
    ARCHIVE_SL_DISASTER: '/archive/student-life/disaster-strikes',
    ARCHIVE_SL_INTEGRATION: '/archive/student-life/integration',
    ARCHIVE_SL_SCHOOL_NEWS: '/archive/student-life/school-news',
    ARCHIVE_SL_STUDENT_PROFILES: '/archive/student-life/student-profiles',

    ARCHIVE_SL_SS: '/archive/student-life/student-successes',
    ARCHIVE_SL_SS_ACADEMICS: '/archive/student-life/student-successes/academics',
    ARCHIVE_SL_SS_GRADUATION: '/archive/student-life/student-successes/graduation',
    ARCHIVE_SL_SS_MISC_VICTORIES: '/archive/student-life/student-successes/miscellaneous-victories',

    ARCHIVE_SL_SE: '/archive/student-life/special-events',
    ARCHIVE_SL_SE_HOLIDAYS: '/archive/student-life/special-events/holidays',
    ARCHIVE_SL_SE_OTHER: '/archive/student-life/special-events/other-events',
    ARCHIVE_SL_SE_PERFORMANCES: '/archive/student-life/special-events/performances',
    ARCHIVE_SL_SE_SPEAKERS: '/archive/student-life/special-events/speakers',

    ARCHIVE_SL_SA: '/archive/student-life/student-activities',
    ARCHIVE_SL_SA_AFTER_SCHOOL: '/archive/student-life/student-activities/after-school',
    ARCHIVE_SL_SA_ATHLETICS: '/archive/student-life/student-activities/athletics',
    ARCHIVE_SL_SA_CANDIDS: '/archive/student-life/student-activities/candids',
    ARCHIVE_SL_SA_CHARITY_WORK: '/archive/student-life/student-activities/charity-work',
    ARCHIVE_SL_SA_CLUBS: '/archive/student-life/student-activities/clubs',
    ARCHIVE_SL_SA_MUSIC: '/archive/student-life/student-activities/music',
    ARCHIVE_SL_SA_SAFETY: '/archive/student-life/student-activities/safety-patrol',
    ARCHIVE_SL_SA_SUMMER: '/archive/student-life/student-activities/summer-programs',
    
    ARCHIVE_SL_SA_D2D: '/archive/student-life/student-activities/day-to-day',
    ARCHIVE_SL_SA_D2D_ACTIVITIES: '/archive/student-life/student-activities/day-to-day/class-activities',
    ARCHIVE_SL_SA_D2D_FIELD_TRIPS: '/archive/student-life/student-activities/day-to-day/field-trips',
    ARCHIVE_SL_SA_D2D_PRE_SCHOOL_AND_HEADSTART: '/archive/student-life/student-activities/day-to-day/pre-school-and-headstart',
};
