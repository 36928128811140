import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import styled from 'styled-components';
import './contact.scss';

const ContactContainer = styled.div`
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 9px;
  margin-top: 30px;

  @media only screen and (min-width: 768px) {
    margin-top: 60px;
  }
`;

const ContactUsForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin-top: 15px;
  align-items: start;
  text-align: left;
`;

const Label = styled.label`
  margin-top: 15px;
  &.no-margin { margin-top: 0px; }
`;

const Heading = styled.h1`
  margin-top: 0;
`;

function Contact() {
  // TODO: Use form instead... https://www.youtube.com/watch?v=VbUmKm6gjtc
  // https://usehooks.com/
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [success, setSuccess] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);

  function recaptchaCallback () {
    setCaptchaVerified(true);
  };

  function recaptchaExpiredCallback () {
    setCaptchaVerified(false);
  };

  function disableFormSubmit() {
    return loading || !name || !email || !message || !captchaVerified;
  };

  function clearForm() {
    setName('');
    setEmail('');
    setMessage('');
    setSubject('');
  };

  async function postData(url = '', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  }

  function submit(event) {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.target);
    const body = {};
    for (var [key, value] of data.entries()) { 
      body[key] = value;
    }
    postData('https://us-central1-howardkennedyhistory.cloudfunctions.net/contact-us', body).then(res => {
      if (res && res.success) {
        // setLoading(false); // make them reload to do it again
        setSuccess(true);
        setFeedback(res.message);
        clearForm();

        setTimeout(() => {
          setSuccess(false);
          setFeedback('');
        }, 10000);
      } else {
        // LOG / REPORT!!
        // setLoading(false); // make them reload to do it again
        setSuccess(false);
        setFeedback("There was an issue sending your message. Please try again later.");
      }
    }).catch(() => {
        // LOG / REPORT!!
        // setLoading(false); // make them reload to do it again
        setSuccess(false);
        setFeedback("There was an issue sending your message. Please try again later.");
    });
  }

  return (
    <ContactContainer>
      <ContactUsForm onSubmit={submit}>
          <Heading>Contact Us</Heading>
          <Label className="no-margin" htmlFor="name">Name *</Label>
          <input placeholder="Your name" type="text" tabIndex="1" id="name" name="name" required autoFocus value={name} onChange={e => setName(e.target.value)} />
          <Label htmlFor="email">Email *</Label>
          <input placeholder="Your Email Address" type="email" id="email" name="email" tabIndex="2" required value={email} onChange={e => setEmail(e.target.value)} />
          <Label htmlFor="subject">Subject</Label>
          <input placeholder="Your Subject" type="subject" id="subject" name="subject" tabIndex="3" value={subject} onChange={e => setSubject(e.target.value)} />
          <Label htmlFor="message">Message *</Label>
          <textarea placeholder="Type your Message Here...." tabIndex="4" id="message" name="message" required value={message} onChange={e => setMessage(e.target.value)} ></textarea>
          <ReCAPTCHA
            sitekey="6Leiut8UAAAAAPaPs40z7rK-moSrufjIHi5t7dGo"
            onChange={recaptchaCallback}
            onExpired={recaptchaExpiredCallback}
          />
          <button className={`${disableFormSubmit() ? 'disabled' : ''}`} name="submit" type="submit" id="contact-submit">Submit</button>
          {feedback && feedback.length && <p className={`feedback ${success ? 'success' : 'error'}`}>{feedback}</p>}
      </ContactUsForm>
    </ContactContainer>
  );
}

export default Contact;
