module.exports = {
    url: "/archive/student-life/student-activities/day-to-day",
    header: "Day To Day",
    description: "What was a regular day like for a student at Kennedy in 1966? What about in 1917? And what exactly was going on at the school in the 1970s? This section will answer those questions and many more that you may have about what Howard Kennedy has been like for the kids who have attended it over the years. We'll cover special assignments, field trips, and the history of pre-school at Kennedy!",
    categories: [
        { name: 'class-activities', img: '/Kennedy Archives/Pictures for Pages/Class Activities.jpg' },
        { name: 'field-trips', img: '/Kennedy Archives/Pictures for Pages/Field Trips.jpg' },
        { name: 'pre-school-and-headstart', img: '/Kennedy Archives/Pictures for Pages/Preschool and Head Start.jpg' },],
    labels: [
    ]
}
