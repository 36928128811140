module.exports = {
    url: "/archive/student-life/special-events/other-events",
    header: "Other Events",
    description: "Here are some articles that can tell us even more about the kinds of events that have taken place at Kennedy, including book fairs, open houses, and carnivals!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1946.4.29.schoolcarnival.pdf",
            label: "This short notice announces the upcoming carnival that the Howard Kennedy PTA was holding in 1946."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1947.4.20.schoolcarnival.pdf",
            label: "This clipping from 1947 describes the recent carnival at Howard Kennedy. Two kindergarteners, Streeter Turner and Marva Johnson, were crowned the king and queen of the carnival!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1949.11.4.openhouse.pdf",
            label: "In this clipping from 1949, the Omaha Star announces the date and time of Kennedy's upcoming Open House for National Education Week."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.2.12.celebrationofmothers.pdf",
            label: "This article from 1954 describes an event where the graduating eighth graders at Howard Kennedy held a fancy tea for their mothers at the school. At the event, both students and mothers were given advice on how to prepare for high school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.11.9 Kennedy award pennants displayed 19540001.jpg",
            label: "This article from 1954 talks about how Howard Kennedy prepared for National Education Week in 1954. This year, in the first floor hallway, they hung over 60 pennants that had been won by students in the 1920s. A pennant is a flag or a banner that is usually awarded for athletics."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1968.3.14.bookfair.pdf",
            label: "This notice from 1968 announces that Howard Kennedy would be holding a book fair."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1968.4.21.bookfair.pdf",
            label: "This clipping from 1968 describes the recent book fair held at Howard Kennedy and shows a picture of the librarian, Florence Maxwell, showing a book to a 10-year-old, Pamela Hill."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1970.4.27.bookfair.pdf",
            label: "This clipping from 1970 talks about a book fair that is held every year at Joslyn Castle to show children what books the Omaha Public Schools may be adding to their libraries. Students from Howard Kennedy, like Regina Smith who is pictured, attended this book fair."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.2.21.volleyball.pdf",
            label: "This clipping from 1974 describes a Valentine's Day volleyball game that had taken place the week before. The game was between the teachers and parents of Howard Kennedy students!"
        }
    ]
}
