// import { routes } from 'src/constants';

module.exports = {
    url: "/archive/alumni",
    header: "Alumni",
    description: "What does life after Kennedy look like? Judging by these articles, it looks pretty amazing! This section is all about Howard Kennedy alumni. Alumni is a word that is used to talk about a group of people who have graduated from a certain school. So Kennedy alumni are people who used to go to the school! And just look at what these Kennedy alumni have accomplished! Please notice that some of these articles are obituaries. An obituary is an article in a newspaper that lets everyone know that a person has died. To find out more about obituaries, take a look at the glossary of terms on the main Archive page. Also, this section is organized alphabetically. That means that it starts with objects about people with last names at the BEGINNING of the alphabet--like Anderson--and ends with the articles and photographs about people with last names at the END of the alphabet--like Washington. You may notice that some of the articles use words that are outdated. We no longer use words like these. Every article with a word like this has a label that suggests other words to use instead.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1.2001.8.2.obit.Alexander,Johnny.Semi-ProBasketballPlayer.pdf",
            label: "This clipping from 2001 is an obituary for Johnny “Flash” Alexander. He grew up to become a semi-pro basketball player for the Harlem Stars."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.2006.8.13.obit.Anderson,Loma.CityofOmahaEmployee.pdf",
            label: "This article from 2006 is an obituary for Loma Anderson. She grew up to become a lifelong employee of the city of Omaha."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1892.2.6.Boots,Alvah.Adventurer.pdf",
            label: "This clipping from 1892 is about Alvah Boots who graduated from the Omaha View School two years earlier. Mr. Boots spent his years graduation going on adventures across America. He died at the age of seventeen."
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1980.11.13.Breakfields.HighSchoolFootballStars.pdf",
            label: "This article talks about Vernon Breakfield who was a football star at both Howard Kennedy and Central High School. It states that both his sons, Vernon, Jr. and Victor, were following in his footsteps by playing football at Burke High School. Mr. Breakfield's wife, Gwen, is a longtime educational assistant at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.2003.5.9.obit.LifelongFriendship.Breakfield.pdf",
            label: "This article from 2003 is an obituary for Vernon Breakfield. It talks about his lifelong friendship with Gale Sayers who he met when they were both students at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/6.1951.9.2.Bryant,Walt.Actor.pdf",
            label: "This article from 1951 is about Walt Bryant. He went from playing football at Kennedy to becoming an actor!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.1985.7.11.Carter,Lorraine.YWCAAward.pdf",
            label: "This article from 1985 is about Lorraine Carter. She had just won an Outstanding Achievement Award from her local Y.W.C.A. for her work in education."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.1950.7.14.Curry,Cherie.Pianist.pdf",
            label: "This notice from 1950 announces a piano performance by Cherie Curry. Ms. Curry had just graduated from Howard Kennedy a month earlier."
        },
        {
            pdf: "Kennedy Archives/AllMedia/10.1969.9.4.Curry,Cherie.Pianist.pdf",
            label: "This notice from 1969 announces a piano performance by Cherie Curry, a Howard Kennedy graduate. When this was printed, Ms. Curry was a professor in the Music Department at San Jose State University in California."
        },
        {
            pdf: "Kennedy Archives/AllMedia/12.1990.7.26.obit.DotsonDavis,EvaMae.Businesswoman.pdf",
            label: "This article from 1990 is an obituary for Eva Mae Dotson Davis. She was a local businesswoman who ran a store called Eva's Dress Shop."
        },
        {
            pdf: "Kennedy Archives/AllMedia/13.2001.7.12.obit.Dryver,Herman.Veteran.pdf",
            label: "This article from 2001 is an obituary for Herman Adellon Dryver. Mr. Dryver was a veteran who fought in World War II."
        },
        {
            pdf: "Kennedy Archives/AllMedia/14.1984.7.26.Edwards,Barbra.HeadNurse.pdf",
            label: "This 1984 clipping is about Barbra Edwards. She had just been made the vice-president of nursing services at Bergan Mercy Hospital which was a very important position."
        },
        {
            pdf: "Kennedy Archives/AllMedia/15.1980.4.10.obit.Ennis,Charles.Dentist.pdf",
            label: "This clipping from 1980 is an obituary for Charles Ennis. He had grown up to become a dentist in California."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.2006.7.6.obit.Faison,John.Veteran.pdf",
            label: "This clipping from 2006 is an obituary for John Faison. He was a veteran who served in the Navy and earned several medals."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.1998.2.26.Favors,Kathryne.Educator.pdf",
            label: "This 1998 article is about Dr. Kathryne Taylor Favors. She had been invited to speak at a conference at the University of Nebraska at Omaha. Dr. Favors was often asked to speak about education at conferences all across America. She both graduated from and taught at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.2008.9.16.obit.Favors,Kathryne.Educator.pdf",
            label: "This article from 2008 is an obituary for Dr. Kathryne Taylor Favors. She was an important educator and speaker. She earned a doctorate at the University of California, Berkley, which is the highest university degree she could get in her field!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1974.12.26.Foxworth,Nilene.Poet.pdf",
            label: "This 1974 article is about Nilene Foxworth who was visiting Omaha from her home in Colorado. She was a poet who used her art to bring together Black people from all different backgrounds."
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1996.2.8.obit.Glenn,Luther.DOCWorker.pdf",
            label: "This article from 1996 is an obituary for Luther Glenn. He had several exciting careers throughout his life and he loved to play the saxophone."
        },
        {
            pdf: "Kennedy Archives/AllMedia/6.1974.6.27.Goodlett,Carlton.NNPAPresident.pdf",
            label: "This article from 1974 is about Carlton Goodlett. He was the editor of the San Francisco Sun Reporter (a newspaper) and the president of the National Newspaper Publishers Association. The article goes over a speech he made about the importance of the fight against racism. You may notice that this article uses the word “ghetto” to talk about some neighborhoods where Black people lived. This is a word that is used to put neighborhoods down. It is important to remember that this word doesn't stand for all of the hard-working and big-hearted people of these neighborhoods. It is better to just call a neighborhood a neighborhood and then use describing words if you think you need to explain more about it."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.2000.3.9.obit.Donaldson,JoAnn.AccountantandCommunityAdvocate.pdf",
            label: "This article from 2000 is an obituary for Joann Marie Donaldson Harris. She was an accountant and a community activist."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.2019.5.20.Henry,Terry.MedStudent.pdf",
            label: "This article form 2019 is about Terry Henry. He had just graduated from Oakwood University in Alabama and was about to enter Meharry Medical College in Tennessee with plans to become a doctor! He stated that a mentor he met when he was a fourth grader at Howard Kennedy had changed his life."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.2002.1.31.Hicks,Phyllis.YouthAdvocate.pdf",
            label: "This article from 2002 celebrates Phyllis Hicks. She was a youth advocate and the leader of the Stepping Saints Drill Team in Omaha."
        },
        {
            pdf: "Kennedy Archives/AllMedia/10.1977.2.17.Hollingsworth,Al.Businessman.pdf",
            label: "This 1977 article is about Al Hollingsworth. Once a student at Kennedy, he went on to run a number of businesses worth millions of dollars!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/12.2007.8.21.Ingram,Robert.SchoolPrincipal.pdf",
            label: "This article from 2007 is about Robert Ingram. He had grown up to become a school principal. At the time this was printed, he was about to get his doctorate in education which was the highest degree he could get in his field!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/13.1978.9.14.Johnson,Lorretta.MSRecipient.pdf",
            label: "This notice from 1978 celebrates Lorretta Johnson. She had become a grade school teacher and had just received her Master's degree in education."
        },
        {
            pdf: "Kennedy Archives/AllMedia/14.1904.5.29.Jones,DBurr.VeteranandMissionary.pdf",
            label: "This article from 1904 is about D. Burr Jones, a former student at the Omaha View School. After fighting in the Philippines in the Spanish-American War, Jones decided to become a Christian missionary."
        },
        {
            pdf: "Kennedy Archives/AllMedia/15.1992.7.28.obit.Kelley,Carl.SoftballCommissioner.pdf",
            label: "This 1992 article is an obituary for Carl P. Kelley. Mr. Kelley was a lifelong leader within the world of Omaha softball. He was once recognized as softball captain of the nation."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.1996.10.24.obit.McCarthy,John.LACountySheriff.pdf",
            label: "This article from 1996 is an obituary for John McCarthy. He was a veteran and a sheriff in California. He also had a doctorate degree in psychology and was a licensed hypnotist!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.1954.5.28.McCaw,Janice.QueenandActress.pdf",
            label: "This article from 1954 announces that Janice McCaw, a Kennedy graduate, had been crowned queen at the yearly Coronation Pageant! It is important to know that this article contains the word “Negro,” which was commonly used more than 50 years ago to talk about African Americans. Language has changed a lot since then and we no longer use that word. Better words to use are “African American” or “Black.”"
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1978.6.15.Merriweather,Wilbert.Mason.pdf",
            label: "This clipping from 1978 celebrates Wilbert Merriweather. He was a Marine Corps veteran and a Mason. The Masons are a secret society of men with groups all across the United States."
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1971.8.19.Miller,Ivan.HighSchoolGraduate.pdf",
            label: "This clipping from 1971 is about Ivan Miller. He had recently graduated from high school and was about to start attending the University of Nebraska, Omaha. This article describes all of his awards and activities in Boys Club and in High School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1968.6.13.Montgomery,Evelyn.Educator.pdf",
            label: "This 1968 article is about Evelyn Montgomery. Mrs. Montgomery was a former student at Howard Kennedy and had gone on to teach there for seventeen years. She had just gotten a promotion to be an assistant in curriculum development!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.1984.5.31.Montgomery,Evelyn.Educator.pdf",
            label: "This notice from 1984 announces that Evelyn Montgomery would be retiring. She was a former Kennedy student and had gone on to teach at the school for seventeen years. At the time that she retired, she was the Director of Elementary Education for OPS."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.1982.6.26.Mosely,John.NotreDamePlayer.pdf",
            label: "This clipping from 1982 is about John Mosley. At the time this article was printed, Mosley was playing football for the University of Notre Dame in Indiana."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.1983.9.7.Mosely,John,NotreDamePlayer.b.pdf",
            label: "This article from 1983 is about John Mosley who was heading into his senior year playing football at the University of Notre Dame. Notre Dame's mascot is the Fighting Irish, and Mosley said that he hoped to help the team succeed for the 1983 season."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.1996.6.25.Mosley,Beverly.LACommissioner.pdf",
            label: "This article from 1996 is about Beverly Mosley who was the Municipal Court commissioner in Los Angeles, California. The clipping states that a commissioner has the same duties and power as a judge!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.1951.6.29.Peters,LorraineandWatts,PhyllisJean.FriendsandWorkingWomen.pdf",
            label: "This notice from 1951 is about Lorraine Peters and Phyllis Jean Watts. The two women had known each other since they started kindergarten at the same time at Kennedy! The clipping announces that they would be spending the summer before their senior year of college working at an office in Chicago."
        },
        
        {
            pdf: "Kennedy Archives/AllMedia/2.1977.2.10.obit.Phillips,Paul.TrackStar.CivilRights.pdf",
            label: "This clipping from 1977 is an obituary for Paul Phillips. He was a civil rights leader and former track star."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1978.12.3.Pittman,Elizabeth.Judge.pdf",
            label: "This 1978 article is about Judge Elizabeth Pittman. At that time, she was the only African American judge in all of Nebraska."
        },


        {
            pdf: "Kennedy Archives/AllMedia/3a.1981.2.26.Pittman,Elizabeth.Judge.pdf",
            label: "This clipping from 1982 lists all the “firsts” achieved by Judge Elizabeth Pittman. She was the first black woman to get a law degree from Creighton University, the first black woman to be named Deputy Douglas County Attorney, the first black person to be elected to the Omaha Board of Education, and so much more!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1943.9.3.Rayford,Eugene.Patriot.pdf",
            label: "This 1943 article is about Eugene Rayford who sold his house and used the money to support the war effort during World War II!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1963.1.6.Sayers,Roger.CollegeAthlete.pdf",
            label: "This article from 1963 is about Roger Sayers who had just been give the title of the World Herald's State College Athlete of the Year. He ran track and played football and had a winning record even though he often had leg injuries."
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.1968.1.18.Sayers,Roger.FamilyMan.pdf",
            label: "This 1968 clipping is from the Omaha Star's “Family of the Week” column. This particular week, the family of Roger Sayers is featured. Mr. Sayers is pictured with his wife and their three children. He was a former college track and football star. At the time the article was printed, he was working to support his family with his job at the Northern Natural Gas Company."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.1970.12.17.Sayers,Gale.NFLPlayer.pdf",
            label: "This article from 1970 is about Gale Sayers, the Kennedy student who made it all the way to the NFL playing for the Chicago Bears. Here he is on a visit to Omaha encouraging young football players to not forget how important it is to get an education."
        },
        {
            pdf: "Kennedy Archives/AllMedia/10.1976.8.19.Sayers,Gale.NFLPlayer.pdf",
            label: "This article from 1976 is about Gale Sayers, the Kennedy student who made it all the way to the NFL playing for the Chicago Bears. He had just become the new athletic director at the University of Southern Illinois, Carbondale, four years after retiring from the NFL. You may notice that this article uses the word “ghetto” to talk about the area in North Omaha where Sayers grew up. This is a word that is used to put neighborhoods down. It is important to remember that this word doesn't stand for all of the hard-working and big-hearted people of these neighborhoods. It is better to just call a neighborhood a neighborhood and then use describing words if you think you need to explain more about it."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.1991.6.16.Sayers,Gale.NFLPlayer.pdf",
            label: "This article from 1991 is about Gale Sayers, the Kennedy student who made it all the way to the NFL playing for the Chicago Bears. He speaks about the importance of getting an education and tells students to not bank everything on being a professional athlete one day. You may notice that this article uses the word “ghetto” to talk about the area in North Omaha where Sayers grew up. This is a word that is used to put neighborhoods down. It is important to remember that this word doesn't stand for all of the hard-working and big-hearted people of these neighborhoods. It is better to just call a neighborhood a neighborhood and then use describing words if you think you need to explain more about it."
        },
        {
            pdf: "Kennedy Archives/AllMedia/13.1967.10.29.Skinner,Eugene.Principal.pdf",
            label: "In this clipping from 1967, Eugene Skinner states that it is important for kids to get their education. At the time, he was a principal at Horace Mann in Omaha. He was the first Black principal in the state of Nebraska."
        },
        {
            pdf: "Kennedy Archives/AllMedia/14.1978.4.6.Skinner,Eugene.OPSAdministrator.pdf",
            label: "This article from 1978 is about Eugene Skinner, the first Black principal in the state of Nebraska. At the time, he was the Assistant Superintendent of Human Relations for OPS."
        },
        {
            pdf: "Kennedy Archives/AllMedia/15.1993.8.5.obit.Skinner,Eugene.OPSAdministrator.pdf",
            label: "This clipping from 1993 is an obituary for Eugene Skinner. Mr. Skinner was the first Black principal in the state of Nebraska. In his younger years, he ran track against Jesse Owens. Skinner Magnet School in Omaha is named after him."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.1980.1.24.Taylor,Odessie.PhDRecipient.pdf",
            label: "This clipping from 1980 announced that Dr. Odessie Taylor had just gotten her doctorate in Philosophy--the highest degree she could get in this field! At the time, Dr. Taylor was the acting Associate Dean at the University of Nebraska College of Nursing."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.1998.1.26.Trotter,Kerry.EuroBasketballPro.pdf",
            label: "This 1998 article is about Kerry Trotter. He played professional basketball for Belgium and wanted to give back to his community. Belgium is a country in Europe."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.2001.5.31.obit.Washburn,Dennis.RecordingStudioOwner.pdf",
            label: "This article from 2001 is an obituary for Dennis Washburn. He was an Air Force veteran and had worked for Union Pacific in the past. He had also run a recording studio in California!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1939.4.16.Washington,Booker.Musicians..pdf",
            label: "This notice from 1939 announces that Booker Washington was going to give a piano concert at Howard Kennedy. He had given a concert at the school every year for four years."
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1955.10.12.Washington,Booker.Musician.pdf",
            label: "This article from 1955 states that Booker Washington, a former student at Kennedy, was giving away one free ticket a week to students at the school. The tickets were for classical music concerts. Mr. Washington was an Army veteran, a composer, and the supervisor of boys and girls clubs at the local Y.M.C.A."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1986.5.1.Washington,Charles.pdf",
            label: "This clipping from 1986 is an obituary for Charles Washington. Named by the Omaha World Herald as one of the most influential Black people in Nebraska, he was a civil rights activist and a powerful journalist. He interviewed Malcolm X when he came back to speak in Omaha and thought of the conversation as the highlight of his career."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1990.5.17.Washington,Charles.pdf",
            label: "This article from 1990 remembers the time, in 1964, when Charles Washington, a former student at Kennedy, interviewed Malcolm X in Omaha. The clipping quotes Charles Washington's thoughts on the importance of the message that Malcolm X had to deliver to the Black people of America."
        },
        {
            pdf: "Kennedy Archives/AllMedia/6.1975.10.16.Williams,JR.Pastor.pdf",
            label: "This article from 1975 announces that J. R. Williams would be the new pastor at St. John A.M.E. Church. The clipping states that he was an amazing musician and singer."
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.1982.6.24.Williams,JR.PastorMusician.pdf",
            label: "This 1982 clipping announces a concert that J. R. Williams would be giving at the St. John A.M.E. Church. He had formerly been the pastor there. In addition to his work in A.M.E. churches, he played the organ for national N.A.A.C.P. conventions."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.2007.4.5.williams,felix.obit.veteran.pdf",
            label: "This article from 2007 is an obituary for Felix Williams. He was an Army veteran who fought in North Africa during World War II. He won a Bronze Star, among other medals."
        }
    ]
}
