import React from 'react';
import styled from 'styled-components'

const FooterContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  min-height: 30px;
  height: 30px;

  border-top: 1px solid lightgrey;
`;

const Love = styled.p`
  margin: 0;
  font-size: 10px;
  color: black;
`;

const NealStanard = styled.a`
  font-size: 10px !important;

  & {
    color: black;
    font-size: 1.2em;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: black;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <Love>Made with &lt;3 by <NealStanard href="https://nealstanard.com" target="_blank" rel="noopener" >Neal Stanard</NealStanard>.</Love>
    </FooterContainer>
  );
}

export default Footer;
