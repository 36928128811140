module.exports = {
    url: "/archive/student-life/student-successes/graduation",
    header: "Graduation",
    description: "The local newspapers used to list all the eighth graders graduating from Kennedy every semester. Here are some of those newspaper clippings!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1892.6.24.graduation.pdf",
            label: "This clipping from 1892 lists all the students in the district who were graduating from the eighth grade that year and moving on to high school. Six of those students were graduating from the Omaha View School!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1915.1.19.8thgradegrads.pdf",
            label: "This article form 1915 lists all the students in the district who were graduating from the eighth grade and moving on to high school. Twenty of those students were graduating from Howard Kennedy!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1917.6.24.grads.pdf",
            label: "This clipping from 1917 shows a picture of three siblings from the same family who were all graduating from Kennedy that year! Their names were Helen, Raymond, and Arthur Willett."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1918.1.25.8thgradegrads.pdf",
            label: "This article from 1918 lists all the students in the district who were graduating from the eighth grade and moving on to high school. Eighteen of those students were graduating from Howard Kennedy!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1952.5.30.8thgradegrads.pdf",
            label: "This clipping from 1952 lists the thirty-two eighth grade graduates from Kennedy that year."
        }
    ]
}
