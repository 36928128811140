module.exports = {
    url: "/notable-figures",
    header: 'Notable Figures',
    description: 'There are many people who have had a role in the history of Howard Kennedy who have not been students or teachers. This section tells the stories of three of those people!',
    categories: [
        { name: 'howard-kennedy', img: '/Kennedy Archives/AllMedia/Howard.Kennedy.1859.jpg' },
        { name: 'mildred-brown', img: '/Kennedy Archives/AllMedia/MildredBrown.photo.jpeg' },
        { name: 'malcolm-x', img: '/Kennedy Archives/AllMedia/malcolm-x-9396195-1-402.jpg' },
    ]
}
