import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { routes } from 'src/constants';

const Nav = styled.ul``;

const NavItem = styled.li`
  display: inline-block;
  margin-left: 30px;

  a {
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  a:visited {
    color: #fff;
  }
`;


const Dropbtn = styled.div`
  color: white;
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    .dropdown-content {
      display: block;
      visibility: visible;
    }
  }
`;

const DropdownContent = styled.div`
  display: none;
  visibility: hidden;
  position: absolute;
  background-color: #2C3E50;
  min-width: 215px;
  z-index: 100;

  a {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      transition: all 1s ease;
    }
  }
`;

const SearchIcon = styled.i`
  height: 25px;
  line-height: 25px;
  cursor: pointer;
`;

function Navigation( {openSearchMenu, className, closeMenuAndSearch} ) {
    return (
        <Nav className={className}>
            <NavItem>
                <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} to="/about">About</Link>
            </NavItem>
            <NavItem>
                {className==='mobile-menu' && <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} to={{
                  pathname: '/archive',
                  state: {
                    glossary: false
                  }
                }}>Archive</Link>}
                {className==='desktop-menu' &&  <Dropdown data-id="archive-dropdown">
                    <Dropbtn aria-haspopup="true" aria-expanded="false"><Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} to="/archive">Archive</Link></Dropbtn>
                    <DropdownContent className="dropdown-content" role="menu">
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/what-is-an-archive">What is an archive?</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/archive/faculty-and-staff">Faculty And Staff</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/archive/alumni">Alumni</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/archive/student-life">Student Life</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/archive/old-school-to-new-school">Old School to New School</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/archive/featured-columns">Featured Columns</Link>
                    </DropdownContent>
                </Dropdown>}
            </NavItem>
            <NavItem>
                {className==='mobile-menu' && <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} to="/notable-figures">Notable Figures</Link>}
                {className==='desktop-menu' &&  <Dropdown data-id="notable-figures-dropdown">
                    <Dropbtn aria-haspopup="true" aria-expanded="false"><Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} to="/notable-figures">Notable Figures</Link></Dropbtn>
                    <DropdownContent className="dropdown-content" role="menu">
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/notable-figures/howard-kennedy">Howard Kennedy</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/notable-figures/malcolm-x">Malcolm X</Link>
                        <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} role="menuitem" to="/notable-figures/mildred-brown">Mildred Brown</Link>
                    </DropdownContent>
                </Dropdown>}
            </NavItem>
            <NavItem>
                <Link onClick={() => {className==='mobile-menu' && closeMenuAndSearch()}} to={`${routes.CONTACT}`}>Contact</Link>
            </NavItem>
            {className=== 'desktop-menu' && <NavItem>
                <SearchIcon className="fa fa-search" onClick={e => openSearchMenu(e)}></SearchIcon>
            </NavItem>}
        </Nav>
    );
}

export default Navigation;
