export const toTitleCase = (str) => {
    const ret = str.toLowerCase().replace(/(?:^|[\s-/])(?!to|the)\w/g, function (match) {
        return match.toUpperCase();
    });

    return ret.replace(/-/g, ' ');
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
