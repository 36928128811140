module.exports = {
    url: "/archive/student-life/student-activities",
    header: "Student Activities",
    description: "Life at Howard Kennedy never slows down. There's always something to get involved in! The objects in this part of the archive cover classroom activities, athletics, all sorts of clubs, and much more!",
    categories: [
        { name: 'after-school', img: '/Kennedy Archives/Pictures for Pages/After School.jpg' },
        { name: 'athletics', img: '/Kennedy Archives/Pictures for Pages/Athletics.jpg' },
        { name: 'candids', img: '/Kennedy Archives/Pictures for Pages/Candids.jpg' },
        { name: 'charity-work', img: '/Kennedy Archives/Pictures for Pages/Charity Work.jpg' },
        { name: 'clubs', img: '/Kennedy Archives/Pictures for Pages/Clubs.jpg' },
        { name: 'day-to-day', img: '/Kennedy Archives/Pictures for Pages/Day-to-Day.jpg' },
        { name: 'music', img: '/Kennedy Archives/Pictures for Pages/Music.jpg' },
        { name: 'safety-patrol', img: '/Kennedy Archives/Pictures for Pages/Safety Patrol.jpg' },
        { name: 'summer-programs', img: '/Kennedy Archives/Pictures for Pages/Summer Programs.jpg' },],
    labels: [
    ]
}
