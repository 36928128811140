module.exports = {
    url: "/archive/student-life/student-activities/safety-patrol",
    header: "Safety Patrol",
    description: "Started in the 1920s, the Howard Kennedy Safety Patrol was an important presence at the school for decades. Safety Patrol members worked hard to enforce traffic laws and keep Kennedy students safe as they crossed the street. Even today, former members of the Safety Patrol talk about how proud they were to wear the sash.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1932.2.5.safetypatrol.a.pdf",
            label: "This short notice from 1932 announces that a thirteen-year-old Safety Patrol captain from Howard Kennedy saved the life of a nine-year-old, Lora Averett, who was almost run over by a truck."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1932.2.5.safetypatrol.b.pdf",
            label: "This clipping from 1932 shows a photograph of two Howard Kennedy students--thirteen-year-old Edwin Riggs and nine-year-old Lora Averett. Edwin was Safety Patrol captain, and he had recently saved Lora's life. This article contains the word “Negro,” which was commonly used more than 50 years ago to talk about African Americans. Language has changed a lot since then and we no longer use that word. Better words to use are “African American” or “Black.”"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1932.2.13.safetypatrol.pdf",
            label: "This article from 1932 states that Howard Kennedy had been given a wrestling mat from the Police Athletic Association. Edwin Riggs, the Safety Patrol captain who had recently saved a girl's life, accepted the mat. This article contains the word “Negroes,” which was commonly used more than 50 years ago to talk about African Americans. Language has changed a lot since then and we no longer use that word. Better words to use are “African Americans” or “Black people.”"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1944.1.29.safetypatrol.pdf",
            label: "In this clipping from 1944, a reporter states that a teenager got into trouble when he didn't stop his car for two members of the Howard Kennedy Safety Patrol as they walked a child across the street. Paying attention to the Safety Patrol was serious business!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1959.9.16.safetypatrol.pdf",
            label: "This clipping from 1959 shows a picture of a member of the Kennedy Safety Patrol at work!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1965.5.5.safetypatrol.pdf",
            label: "This article from 1965 is about a day when all the members of the Safety Patrol were on a field trip. While they were away, their mothers became Safety Patrol members for the day!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.9.6.safetypatrol.a.pdf",
            label: "This article from 1974 is about two Howard Kennedy Safety Patrol officers who kept things under control after a big car accident took place on the street in front of them. They made sure everyone remained calm and that the day continued to go smoothly."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.9.12.safetypatrol.pdf",
            label: "This notice from 1974 announces that the two Kennedy Safety Patrol officers who had kept things calm after a big car crash had been given awards by the Mayor for their work."
        }
    ]
}
