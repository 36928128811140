module.exports = {
    url: "/archive/student-life/student-activities/charity-work",
    header: "Charity Work",
    description: "This section is filled with examples of ways in which students at Kennedy have helped others over the years. From helping with the war effort during World War I and World War II to making mittens for people in their community, kids at Kennedy have always made a difference.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1917.5.24.charitywork.pdf",
            label: "This clipping from 1917 states that students at Howard Kennedy had raised enough pennies and dimes to get a Red Cross banner for each classroom at the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1918.2.3.charitywork.pdf",
            label: "This article from 1918 desribes a tinfoil drive put on by Omaha's Camp Fire Girls. The Camp Fire Girls was a group that taught young girls outdoor skills. At the time this article was printed, the students at Howard Kennedy had already collected 63.5 pounds of tin foil! Drives like this one were held to help with the war effort during World War I."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1918.3.23.charitywork.pdf",
            label: "This clipping from 1918 talks about a nine-year-old from Howard Kennedy named Joe Bixler, who raised the most money for a fundraiser during World War I."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1942.11.20.charitywork.pdf",
            label: "This article from 1942 announces that students from both Kennedy and Long School had been gathering old iron to support the war effort during World War II. Howard Kennedy's students collected 12,040 pounds of iron!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1943.3.21.charitywork.pdf",
            label: "This article from 1943 is about a poster that was put up at Howard Kennedy showing how much money students had donated to the Red Cross War Fund during World War II."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1943.4.18.charitywork.pdf",
            label: "This clipping from 1943 shows a photograph of students from Howard Kennedy who brought food and other goods to the new USO building. The old USO building had burned down a few weeks earlier. The USO is a charity organization that helps members of the military and their families."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1945.6.5.charitywork.pdf",
            label: "In this article from 1945, it is stated that Howard Kennedy's students won second place in a contest to see which school's students could pick up the most litter."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1956.2.2.charitywork.pdf",
            label: "This article from 1956 states that the sixth graders at Howard Kennedy donated their own money to a March of Dimes fundraiser. When this article was printed, the March of Dimes was a charity that was pushing to end polio--a disease that affected children. Now the March of Dimes fights to make sure that all mothers and babies are healthy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957.2.17.charitywork.pdf",
            label: "This clipping from 1957 has a photograph of four Howard Kennedy students who were helping to prepare for an event called Heart Sunday."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.5.3.charitywork.pdf",
            label: "This clipping from 1958 is about a group of students--many from Kennedy--who went door-to-door asking people to do their part in cleaning up their neighborhood."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1971.11.26.charitywork.pdf",
            label: "This notice from 1971 talks about a student council project at Kennedy in which students donated food to a nearby food pantry."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.11.29.charitywork.pdf",
            label: "This article from 1974 states that students at Howard Kennedy had been making mittens to give to children who couldn't afford them at Christmas."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2002.5.2.charitywork.pdf",
            label: "This article from 2002 explains that students from Howard Kennedy had spent the week visiting senior citizens at Florence Nursing Home. Before they left, they handed out homemade cards and gifts to the seniors!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2002.12.5.charitywork.pdf",
            label: "This article from 2002 announces that students at Kennedy filled up four barrels with food for the Omaha Food Bank!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2011.12.24.charitywork.pdf",
            label: "This article from 2011 lists good deeds that were being carried out by students at schools all across the Omaha area. Students at Howard Kennedy raised money to help build a well in Mali so that families there could have clean water. Mali is a country in Africa."
        }
    ]
}
