module.exports = {
    url: "/archive/old-school-to-new-school/omaha-view-disrepair",
    header: "Omaha View Disrepair",
    description: "The Omaha View School building started having problems just a few years after it was first built in the late 1880s. The objects in this part of the archive show how conditions at the school went from bad to worse until the school board finally agreed to construct another building to replace it. But the school board was thrifty! When they tore down the old building, they used its bricks to build a much-needed warehouse!",
    labels: [
        {
        pdf: "Kennedy Archives/AllMedia/1892.10.11.badplumbing.pdf",
        label: "This article contains a list of subjects that the school board talked about in October of 1892. The Omaha View School's broken toilets were on the list! The school board didn't want to spend the money to fix them."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1893.9.26.lackofcoal.pdf",
        label: "In this clipping from 1893, a reporter writes that a few schools had to close for the day because it was too cold outside and they couldn't be heated. The Omaha View School was one of those schools. The school didn't have enough coal to light its fires! Can you imagine having a fire in a stove in your classroom?"
    },
    {
        pdf: "Kennedy Archives/AllMedia/1893.10.20.safetyconcerns.pdf",
        label: "This article from 1893 talks about a recent meeting of the Omaha View Improvment Club. Members were worried that the Omaha View School wasn't safe for children to attend."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1893.10.29.badplumbing.pdf",
        label: "Here, a reporter writes in 1893 that the plumbing at the Omaha View School is in bad shape because it was built very cheaply. An inspector worried that the school was not clean enough for kids to use."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1894.3.1.callforplumbers.pdf",
        label: "This clipping from 1894 is a notice printed in the Omaha World Herald asking for plumbers to come up with ideas to fix the plumbing at the Omaha View School. There were many notices like this that showed up in the newspaper asking for help with repairs at the school."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1894.5.27.ventilationproblem.pdf",
        label: "This article from 1894 states that the plumbing at the Omaha View School was in such bad shape that the smell of the toilets reached everywhere in the school. The reporter writes that the smell was so bad, it was making students and teachers sick."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1895.11.23.brokenboiler.pdf",
        label: "This clipping from 1895 reports that the boiler that helped to heat the Omaha View School had a crack in it. School was called off for the day so that it could be fixed. If the boiler had exploded, it could have seriously hurt whomever was there."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1896.7.9.newboiler.pdf",
        label: "This short notice from 1896 reports that the Omaha View School would be getting a new boiler, six months after the last one broke! The boiler would help to heat the school."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1908.3.6.condemned.pdf",
        label: "In this article from 1908, it is stated that though the Omaha View School was condemned the year before, it was still open and being used by teachers and students. When a builing is condemned, it is usually unsafe and about to be torn down. The reporter writes that the school board was beginning to talk about building a new school to replace this dangerous one."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1908.10.23.cantbeusedonwindyday.pdf",
        label: "This article from 1908 contains a list of all the problems with the Omaha View School building, including cracks in the walls, sagging ceilings, bad plumbing, and bad fire escapes. It wasn't even safe to be in the school on a windy day because the principal was afraid it wouldn't stay standing!"
    },
    {
        pdf: "Kennedy Archives/AllMedia/1908.10.24.temporaryrepairs.pdf",
        label: "In this clipping from 1908, an official states that some repairs would be made to the Omaha View School so that the school could still be used until the new building was built."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1908.11.5.threatentoclose.pdf",
        label: "In this article from 1908, an official threatens to close the Omaha View School unless some repairs are made."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1908.11.10.temporaryrepairs.pdf",
        label: "This article from 1908 states that the Omaha View School would not be closed because the repairs that the building inspector asked for were carried out."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1908.OmahaView1908.png",
        label: "This is a picture of the old Omaha View School in 1908, just a couple of years before it was torn down."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1910.2.1.useofmaterials.pdf",
        label: "The new Omaha View School had just opened! What would happen with the old building? This article from 1910 states that the building would be torn down and the bricks would be used to build a new warehouse for the Superintendent of Buildings."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1910.11.21.torndown.pdf",
        label: "This notice lists some buildings from 1910 that had recently been torn down. One of these buildings was the old Omaha View School."
    },
    {
        pdf: "Kennedy Archives/AllMedia/1911.8.15.wherethebricksendedup.pdf",
        label: "This article from 1911 announces that school supplies and furniture were being moved into the new warehouse that had just been built using bricks from the old Omaha View School."
    }
]
}
