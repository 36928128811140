module.exports = {
    url: "/archive/student-life/special-events/performances",
    header: "Performances",
    description: "This section has articles about programs and plays that have been performed at Kennedy since the school opened its doors. Take a look at the articles to see pictures of some of these events!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1912.5.30.program.pdf",
            label: "This clipping from 1912 is about a celebration that was held at Howard Kennedy in honor of the end of the school year. The upper grades gave a program and the younger grades waited tables. There were 600 students at the event, plus their families!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1914.4.4.packedprogram.pdf",
            label: "This article from 1914 describes how 2,000 people showed up to see a program given by the students of Howard Kennedy. They couldn't all fit in the building!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1938.6.22.musicprogram.pdf",
            label: "This notice from 1938 states that seventh and eighth graders from Howard Kennedy gave a program outside of the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1962.12.16.musicprogram.pdf",
            label: "This clipping from 1962 shows a photograph of fourth graders wearing matching hats and playing flutes to prepare for their upcoming program."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.6.6.theatre.pdf",
            label: "This clipping from 1974 shows three pictures of students at Howard Kennedy performing a play about the Gingerbread Man."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2004.5.6.musicprogram.pdf",
            label: "This article from 2004 describes an upcoming program called Celebrate People that the students of Howard Kennedy were giving to honor alumni and school volunteers. There are several photos of the children singing at an earlier performance."
        },
    ]
}
