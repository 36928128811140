module.exports = {
    url: "/archive/student-life/student-activities/music",
    header: "Music",
    description: "Music has been an important part of Kennedy for many decades! The objects in this section will tell stories about the school's musical history, which has involved strings, flutes, choirs, and so much more!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1950.12.15.choir.pdf",
            label: "This notice from 1950 states that both the Howard Kennedy choir and band would be performing in a Christmas program at the Y.M.C.A."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1964.5.22.music.pdf",
            label: "This clipping from 1964 shows a picture of first graders from Howard Kennedy doing a dance with paper clocks while older students play their song flutes. A song flute is like a recorder but smaller."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1965.10.24.music.pdf",
            label: "This article from 1965 announces that Howard Kennedy and three other OPS schools would be getting an electric organ for students to use! An electric organ is an electronic keyboard that imitates sounds made by real-life organs and other instruments."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1966.4.8.music.pdf",
            label: "This clipping from 1966 contains a picture of three fourth graders at Howard Kennedy learning to play the school's new electric organ as a teacher watches over them. An electric organ is an electronic keyboard that imitates sounds made by real-life organs and other instruments."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1972.Kennedy.piano.lessons.1972.jpg",
            label: "This photograph from 1972 shows students at Howard Kennedy taking piano lessons on small pianos. Both the students and their teachers are wearing headphones."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2003.Kennedy.violins.2003.jpg",
            label: "This photograph from 2003 shows Howard Kennedy students playing violins and violas."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2012.12.25.music.pdf",
            label: "This article tells the story of when, in 2012, the Noblemen who volunteer at Kennedy raised money and donated violins to the school. The students at Kennedy used them in their strings class and at their Christmas concert."
        }
    ]
}
