module.exports = {
    url: "/archive/old-school-to-new-school",
    header: "Old School to New School",
    description: "Did you know that Howard Kennedy Elementary School used to be called the Omaha View School? It was even in a different building! The building that we know of today as Howard Kennedy was built in 1910 when the original Omaha View School building became too dangerous to use. Since then, Kennedy has had an addition built in 1957 and a renovation in 2004. This section of the archive has more information about all those changes, as well as articles that show how Howard Kennedy continues to move into the future! All of the articles in this section of the archive are organized in chronological order. That means the articles with the earliest dates--like those from the 1890s--will come first, and the articles with the most recent dates--like those from the 2010s--will come last. You may notice that some of the articles use words that are outdated. We no longer use words like these because they are disrespectful. Every article with a word like this has a label that suggests other words to use instead.",
    categories: [
        { name: 'omaha-view-disrepair', img: '/Kennedy Archives/Pictures for Pages/Omaha View School Disrepair.png' },
        { name: 'howard-kennedy-opening', img: '/Kennedy Archives/Pictures for Pages/Kennedy Opening.jpg' },
        { name: 'howard-kennedy-changes', img: '/Kennedy Archives/Pictures for Pages/Kennedy Changes.jpg' }, 
        { name: 'future-of-kennedy', img: '/Kennedy Archives/Pictures for Pages/Future Of Kennedy.jpg' },   
    ],
    labels: [
    ]
}
