module.exports = {
    url: "/archive/student-life/disaster-strikes",
    header: "Disaster Strikes",
    description: "We all know that not every day at school goes smoothly. Sometimes things just don’t go our way. And sometimes, DISASTER STRIKES! All of these articles talk about days when things just went sideways. Scroll through the clippings to find out more!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1902.4.27.windblowssidewalksaway.pdf",
            label: "This article from 1902 lists all the places in Omaha that were damaged one very windy night. The sidewalks outside the Omaha View School were blown up and away!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.4.3.accidentalfiredrill.pdf",
            label: "In this article from 1910, an ice cream man makes a big mistake on a visit to the new Howard Kennedy school. He set off the fire alarm and caused the first fire drill ever to happen in the building!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.6.21.ratinvasion.pdf",
            label: "This clipping tells the story of when rats invaded Howard Kennedy. Luckily, it seems like this problem was taken care of back in 1910!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1917.1.8.smallpox.pdf",
            label: "Smallpox at Kennedy?! This article from 1917 says that 4 people near the school had this illness! We don’t see smallpox around much today, but it was a deadly disease in the past."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1917.2.21.boybreaksleg.pdf",
            label: "In this article from 1917, a boy at Kennedy named Edward Dunn breaks his leg while wrestling with friends in the basement. The custodian had to carry him home!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1927.9.9.heatwave.pdf",
            label: "In this article from 1927, students at Druid Hill had to leave school early because of the heatwave outside, but the kids at Howard Kennedy finished their school day. We’re lucky to have air conditioning at Kennedy today!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1939.9.15.heatwave.pdf",
            label: "This time, in 1939, the heatwave was so bad that all the elementary schools closed early. At Kennedy, some kids even got nosebleeds from the heat!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1943.3.28.girlhitshead.pdf",
            label: "In this 1943 article, Jean Muller, a Nursery School student at Kennedy, got a bump on her head when she fell. It looks like the police even came to check on her!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1955.4.27.girlhitbyplaster.pdf",
            label: "In this article from 1955, a piece of the ceiling fell and hit six-year-old Jessica Smith on the head. She needed 3 stitches at the hospital."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1955.11.11.diptheria.pdf",
            label: "Diphtheria is another dangerous disease we don’t see much around Omaha anymore. In 1955, the principal at Howard Kennedy wanted to make sure that all the students got their shots so that they would be safe from the illness!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1961.11.29.airraiddrill.jpg",
            label: "In this article from 1961, the students of Howard Kennedy had a civil defense drill—also called an air raid drill. During this drill, the students had to duck and cover their heads in case of an atomic bomb."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1961.12.4.airraiddrillopinion.pdf",
            label: "This is a letter that someone wrote to the Omaha World Herald in 1961 after seeing the article about the civil defense drill at Kennedy. Clearly, this reader thought the drill was silly and useless."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1990.11.8.boyhitbycar.pdf",
            label: "This article from 1990 talks about Benjamin Herek, who was hit by a car on his way to Kennedy. It was scary for everyone, but it looks like he felt much better after a trip to the hospital and some prayers from his mother."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1992.11.18.poweroutage.pdf",
            label: "When the power went out at Howard Kennedy in 1992, the students and teachers came together and made their school day work! They even had a little fun!"
        }
    ]
}
