module.exports = {
    url: "/archive/student-life/student-successes/academics",
    header: "Academics",
    description: "This section describes student successes in school subjects, including writing and spelling, as well as awards for attendance.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1899.12.24.writing.pdf",
            label: "In this notice from 1899, an Omaha View School student, Violet Patton, is recognized for winning a gold medal in an eighth grade writing contest. Both of Violet's parents had died, so she was working to get her education."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1918.6.13.penmanship.pdf",
            label: "This article from 1918 announces the names of the students across the district who won awards for penmanship. Alice Watson, a student at Howard Kennedy, was listed as being one of the ten best writers in the district!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1929.4.12.spelling.pdf",
            label: "This clipping from 1929 shows a picture the Howard Kennedy spelling bee winner, James Payton, an eighth grader. He would go on to compete in the city spelling championship."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1972.7.20.attendance.pdf",
            label: "This 1972 article contains pictures of every student who had almost perfect attendance during summer school at Howard Kennedy that year."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.3.14.writing.pdf",
            label: "This article from 1974 announces the winners of a local writing contest. Cheryl Mix, a sixth grader at Howard Kennedy, took first place!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1980.6.17.writing.pdf",
            label: "In this article from 1980, it is stated that a piece of writing by a Howard Kennedy student was accepted into the district's yearly book of student works. Third grader Mike Butts just wrote one powerful sentence. Read on to find out what he wrote!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1982.4.18.writing.pdf",
            label: "This 1982 article announces that True Vang, a third grader at Kennedy, had won first place in an Omaha writing contest. True wrote about the difference between his old home in Laos and his new home in Omaha. He won $60."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1989.10.8.attendance.pdf",
            label: "This article from 1989 announces that thirteen elementary, middle, and high school students had been awarded $100 savings bonds. One of these students, Christopher Govan, went to Howard Kennedy. A savings bond is a piece of paper that gains interest (money) over time. An $100 savings bond from 1989 could be worth much more today!"
        }
    ]
}
