module.exports = {
    url: "/archive/student-life/student-activities/candids",
    header: "Candids",
    description: "A “candid” is usually a photograph taken of a person in action--especially when they don't know their picture is being taken. This section is labeled “Candids” because it is full of both photos AND stories of life at Kennedy being lived to the fullest!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1914.1.23.boyslookingforfire.pdf",
            label: "This is a little story about two 6-year-old boys from Howard Kennedy who chased after a fire wagon and got lost. That's right--a fire wagon! Omaha didn't have fire trucks in 1914!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1914.Kennedy%2019140001.jpg",
            label: "This is a photograph of people--probably students and teachers--standing in front of Howard Kennedy in 1914. It's a little faded, but if you look closely, you can see the kinds of outfits that kids wore more than 100 years ago!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1928.2.15.snowballvingette.pdf",
            label: "This clipping from 1928 is a short story of the principal at Howard Kennedy, Grace Griffith, asking a student what he was doing with a snowball in his hand!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1949.10.6.fads.pdf",
            label: "This article from 1949 states that the Omaha Public Schools have banned yo-yos in classrooms. Yo-yos were all the rage. A professional yo-yo demonstrator even had to be asked to leave Howard Kennedy!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1954.9.7.studentregistration.pdf",
            label: "This clipping from 1954 shows two Kennedy students, Kathie Francine Tucker and Sharon Tucker, registering for school with their mother."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.Kennedy%20Classroom%20Jan%2019580001.jpg",
            label: "This is a photo of a classroom at Kennedy in 1958."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.Kennedy.classroom0001.jpg",
            label: "This photo is of a classroom full of students at Howard Kennedy in 1958. What do you see on the bulletin board behind the children?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.Kennedy.library.19580001.jpg",
            label: "This is a photo of some students doing schoolwork in the library at Howard Kennedy in 1958."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.Kennedy.nurses.office.19580001.jpg",
            label: "This photograph shows the school nurse and three students in the nurse's office at Howard Kennedy in 1958."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1959.1.11Kenned%201-11-58%20hallway0001.jpg",
            label: "This is a photo of a hallway in the new addition that had just been built onto Kennedy in 1958."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.Kennedy.crocheters0001.jpg",
            label: "This photograph from 1974 shows three Howard Kennedy students crocheting mittens. Crocheting is like knitting--they both use yarn!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1977.12.25.howardkennedysteps.pdf",
            label: "This clipping shows a photograph of several students near and on Howard Kennedy's front steps in 1977."
        },
        {
            pdf: "Kennedy Archives/AllMedia/LibGaz0002.candids.png",
            label: "This is a photograph of some children and two teachers under the gazebo that was in the library on an unknown date."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1980s.Kennedy.n.d.jpg",
            label: "Here is another photograph of students on Howard Kennedy's front steps."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1982.10.6.boyonscooter.pdf",
            label: "This clipping from 1982 shows a third grader named Brian Randolph waiting for the bus that would take him to Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1983.5.28.lifeskills.pdf",
            label: "In this clipping from 1983, a photograph of students at Kennedy playing with a giant parachute is shown."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1983.9.21.kidswalkinghome.pdf",
            label: "This clipping from 1983 shows a photograph of two students, Melinda and Leon Stubberfield, walking home from Howard Kennedy, bundled up in jackets."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1992.5.26.fads.pdf",
            label: "This is an article about a candy that was popular across Omaha in 1992--super-sour gum balls. In the article, Rhonda Steed, a student at Howard Kennedy, says that her sister tricked her into eating one!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2000s.Kennedy.Mad.Science.n.d.jpg",
            label: "This photograph shows three Kennedy students participating in a science activity."
        }
    ]
}
