module.exports = {
    url: "/archive/featured-columns/anastasias-almanac",
    header: "Anastasia's Almanac",
    description: "“Anastasia’s Almanac” was a column in the Omaha World Herald full of letters that people would send in asking questions, giving advice, and talking about their childhood memories. In 1958, five letters showed up in “Anastasia’s Almanac” from writers remembering their time at the Omaha View School!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1958.7.27.memories.pdf",
            label: "This writer kicked off the Omaha View School memories by writing about memorizing a poem in the fourth grade more than 50 years earlier."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.8.24.memories.pdf",
            label: "This writer, Marie, speaks about the “wonderful view” from the school. In fact, the school sat on top of a hill and was named for the incredible view of Omaha from its windows. Marie also speaks about some of her old teachers at the Omaha View School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.9.7.memories.pdf",
            label: "This writer, Bess, also remembers the view from the school. She went to the Omaha View School in 1896 and writes about the long walk up the hill to get there."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.9.21.memories.pdf",
            label: "Georgia, another writer, remembers when a blizzard started during the school day and the teachers had to walk each of the children home. She also writes about running all the way to school when she would hear the bell ring."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.10.12.memories.pdf",
            label: "Mrs. Alice writes that her father was a janitor at the Omaha View School in the 1800s. She remembers a song that the music teacher, Miss Arnold, taught the students."
        },
    ]
}
