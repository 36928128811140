import React from 'react';
import styled from 'styled-components'
import messages from './messages';
import ArchiveLayout from 'src/Components/Layout/ArchiveLayout';
import Categories from 'src/Components/Categories';

const NotableFiguresContainer = styled.div`
`;

// const NotableFiguresRow = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-direction: column;

//   @media only screen and (min-width: 768px) {
//     flex-direction: row;
//   }
// `;

// const NotableFigure = styled.div`
//   display: inline-block;
//   margin: 0 5px;

//   img {
//     width: 100%;
//   }
// `;

function NotableFigures() {
  return (
    <NotableFiguresContainer>
      <ArchiveLayout route={messages.url} description={messages.description}
        header={messages.header}>
        <Categories route={messages.url} categories={messages.categories} classes="pos-22"></Categories>
      </ArchiveLayout>
    </NotableFiguresContainer>
  );
}

export default NotableFigures;
