import React from 'react';
import styled from 'styled-components';
import Labels from 'src/Components/Labels';
import Section from 'src/Components/Layout/Section/index.js';
import Breadcrumbs from 'src/Components/Breadcrumbs/index.js';
import messages from './messages';

const MildredBrownContainer = styled.div`
`;

// DUPLICATED
const CategoryImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 15px 30px;
`;

const NewsPaperLayout = styled.div`
  display: block;
  content: "";
  clear: both;
  margin-bottom: 60px;

  img.right { float: right; }
  img.left { float: left; }
`;

// const PDF = styled.object`
//     height: 500px;
//     margin-bottom: 30px;

//     @media only screen and (min-width: 768px) {
//         height: 500px;
//     }
// `;
// DUPLICATED

function MildredBrown() {
  return (
    <MildredBrownContainer>
      <Section>
        <Breadcrumbs route={messages.url}></Breadcrumbs>
        <h1>{messages.header}</h1>
        <p>Mildred Brown moved to North Omaha with her husband and adopted daughter, Ruth, in 1937. Originally from Alabama, she and her family were a part of the Great Migration, which was the journey of millions of African Americans from the 1910s through the 1970s from the American South to other parts of the United States. Though these people were moving in order to escape racially-based segregation and violence, they still encountered much prejudice in their new towns and cities--including in Omaha. Over the next 50 years, Mildred Brown would be a major figure in the fight for civil rights for Black people in Omaha.</p>
        <NewsPaperLayout>
          <CategoryImage className="right" src="/Kennedy Archives/Notable Figures/MB/MildredBrown.photo.jpeg" />          
          <p>A year after arriving in Omaha, Mildred and her husband, Dr. Shirley Edward Gilbert, founded the Omaha Star newspaper. The two divorced in 1943 and from that time on, Mildred ran and edited the newspaper on her own. Her goal from the start was to publish stories that would show African Americans in a positive light--showcasing the successes of the people of North Omaha. She used the Omaha Star to expose racist hiring practices, gain support for local sit-ins, and push for racial equality in the school system. Throughout her career, she worked with local and national groups in the fight for civil rights. At times in the 1960s, she was even asked to consult personally with President Lyndon B. Johnson.</p>
          <p>She also took a special interest in the children of North Omaha--including many from Howard Kennedy! A former teacher herself, she made it a point to hire kids from around the neighborhood as newspaper carriers--also known as newsies. She led her young employees by example, giving them a work ethic and teaching them to fight for what they knew was right.  “Newspapers & Butter Pecan Ice Cream: Mrs. Mildred Brown and the Omaha Star” by Amy Helene Forss tells the true story of how Mildred led a successful boycott of Reed’s Ice Cream company with the help of her newsies. This story is just one example of how she used her newspaper to teach new generations how to stand up for their rights.</p>
          <CategoryImage className="left" src="/Kennedy Archives/Notable Figures/MB/1958.7.31.congratstostar.picture.jpg" />
          <p>Mildred Brown continued to run the Omaha Star until the day that she died in 1989, and it is still being printed today. It is the longest-running newspaper in the United States that was founded by an African American woman. And North Omaha does not forget Mildred Brown. Three books about her, written by Amy Helene Forss, have recently been released so that her story can continue to be told. Those who knew her still talk about her with deep respect. She lives on in all the progressive changes that she helped to make possible, and in every new copy of the Omaha Star. </p>
          <p><i>Look below for more information about Mildred Brown.</i></p>
        </NewsPaperLayout>
        <Labels labels={messages.labels}></Labels>
        <a target="_blank" rel="noopener noreferrer" href="https://theomahastar.com/" >theomahastar.com</a>
      </Section>
    </MildredBrownContainer>
  );
}

export default MildredBrown;
