module.exports = {
    url: "/archive/old-school-to-new-school/future-of-kennedy",
    header: "Future of Kennedy",
    description: "Anyone who has spent time at Kennedy in the last five years knows that the school continues to change and grow towards the future. This part of the archive contains clippings about this growth, as Howard Kennedy has partnered with the non-profit, 75 North, to support students and families in new and exciting ways.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/2015.4.7.kennedyimprovement.pdf",
            label: "This article from 2015 states that the school board decided that 75 North, along with Purpose Built Communities, were approved to work with Howard Kennedy to shake up the school and add a longer school day, STEAM curriculum, a new principal, and other huge changes. The goal would be to make Kennedy a high-performing school to go along with the revitalization program that had already begun in the Highlander neighborhood. TO revitalize something means to bring new life and activity to it."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2015.5.22.principalinterviews.pdf",
            label: "This article from 2015 is about the interviews that were happening to hire a new principal at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2015.10.10.highlandergroundbreaking.pdf",
            label: "This article from 2015 is about the groundbreaking for the new Highlander development, which is a part of the same revitalization project that is happening at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2015.10.20.tonyshomework.pdf",
            label: "In this article from 2015, Mr. Gunter is described doing research and working out ways to bring new programs and ideas to Howard Kennedy, including STEAM curriculum, class pets, more musical instruments, and other projects that we now see at the school all the time. The article calls this work Mr. Gunter's homework!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2016.6.22.teacherprep.pdf",
            label: "This article from 2016 is about the steps that were taken to prepare new and longtime staff at Kennedy for the changes that were going to happen in the Fall of 2016. Mr. Gunter and two of the teachers are interviewed."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2016.8.9.backtoschool.a.jpg",
            label: "This clipping from 2016 is the first part of an Omaha World Herald article about Kennedy's Open House in the Fall of 2016. At this event, teachers welcomed students and their families back to school after Summer break. It was a chance for kids and parents to get to know new teachers and learn about the changes that were happening at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2016.8.9.backtoschool.b.jpg",
            label: "This clipping from 2016 is the second part of an Omaha World Herald article about Kennedy's Open House in the Fall of 2016. At this event, teachers welcomed students and their families back to school after Summer break. It was a chance for kids and parents to get to know new teachers and learn about the changes that were happening at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2016.8.9.backtoschool.c.jpg",
            label: "This clipping from 2016 is the third part of an Omaha World Herald article about Kennedy's Open House in the Fall of 2016. At this event, teachers welcomed students and their families back to school after Summer break. It was a chance for kids and parents to get to know new teachers and learn about the changes that were happening at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2018.1.7.paddleforwardaward.pdf",
            label: "This article from 2018 describes many events that had recently taken place in Omaha. One of the pieces of news on the list was that Mr. Gunter won the 2017 Paddle Forward Award at an Outward Bound School benefit lunch. This was a sign that things were really happening at Kennedy!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2019.2.3.earlylearningcenterconstruction.pdf",
            label: "This article from 2019 lists a number of buildings being constructed in Omaha. One of the buildings mentioned was the Early Learning Center that was being built by Kennedy. The Center opened in January of 2020 and the Head Start students and teachers who had been in the Howard Kennedy building have now moved to the new building. Kennedy just keeps growing!"
        }
    ]
}
