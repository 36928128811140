import React from 'react';
import SectionContainer from './SectionContainer';

function Section({maxWidth, children, marginTop='0'}) {
  let width = 0;

  switch(maxWidth) {
    case 'sm':
      width = 960;
      break;
    case 'md':
      width = 1024;
      break;
      case 'xlg':
      width = 1440;
      break;
    default:
      width = 1280;
  }

  return (
    <SectionContainer marginTop={marginTop} width={width}>
        {children}
    </SectionContainer>
  );
}

export default Section;
