import styled from 'styled-components';

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding: 15px;

    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: ${props => `${props.marginTop}px`};
    
    max-width: ${props => `${props.width}px`};

    @media only screen and (min-width: 768px) {
        padding: 30px;
    }
`;

export default SectionContainer;
