module.exports = {
    url: "/archive/student-life/student-successes",
    header: "Student Successes",
    description: "Howard Kennedy has a long history of students who have worked hard to achieve their goals. This section covers the many successes of these students over the years, like graduations, awards for academic skills, and artistic victories!",
    categories: [
        { name: 'academics', img: '/Kennedy Archives/Pictures for Pages/Academics.jpg' },
        { name: 'graduation', img: '/Kennedy Archives/Pictures for Pages/Graduations.jpg' },
        { name: 'miscellaneous-victories', img: '/Kennedy Archives/Pictures for Pages/Misc.jpg' },],
    labels: [
    ]
}
