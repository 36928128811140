import React from 'react';
import styled from 'styled-components';
import ArchiveLayout from 'src/Components/Layout/ArchiveLayout';
import Labels from 'src/Components/Labels';
import messages from './messages';

const PageContainer = styled.div`
`;

function Page() {
  return (
    <PageContainer>
      <ArchiveLayout route={messages.url} description={messages.description}
        header={messages.header}>

        <Labels labels={messages.labels}></Labels>
      </ArchiveLayout>
    </PageContainer>
  );
}

export default Page;
