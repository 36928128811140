module.exports = {
    url: "/archive/student-life/integration",
    header: "Integration",
    description: "Before the 1970s, the schools in OPS had de facto segregation. That meant that although there wasn't a law saying that Black and white children should go to separate schools because of their race, they usually did because of segregated housing practices, which pushed African Americans into certain areas. In 1976, a federal court ordered the Omaha Public School District to desegregate its schools. Schools like Howard Kennedy, which were historically African American, sent students on buses to schools mostly made up of white children. Those schools that had traditionally mostly enrolled white children sent many of their students to schools like Kennedy. These articles can give us a small idea of what that experience was like until the 1999-2000 school year, when Kennedy began to mainly enroll students from its own neighborhood again.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1976.8.18.busingschedule.pdf",
            label: "This is part of a larger article from 1976 about the Omaha Public School district's new busing plan. Middle school-aged students who would have gone to Kennedy before desgregation were being bused to Beveridge Middle School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1976.8.26.busingmap.pdf",
            label: "This page came in the Omaha World Herald newspaper in August of 1976. It contains a map and and map keys explaining which schools would have their students bused to which other schools as OPS carried out its desegregation plan. If you look closely, you can see which schools students who would have gone to Howard Kennedy would now be attending."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1976.9.6.busingissues.pdf",
            label: "This is part of a larger article from September of 1976 which talks about some of the problems that OPS was having with the new busing plan. Mostly it seems like the busing company they were using was having trouble getting used the schedule. For example, one bus showed up two hours late to Howard Kennedy! But they must have worked the problems out, because busing like this continued for almost twenty-five years!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1977.9.6.busingimprovement.pdf",
            label: "In this section of a larger article from September of 1977, a teacher at Kennedy says that the second year of busing was off to a great start!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1992.10.22.promotingintegration.pdf",
            label: "This article from 1992 is about a teacher at Saratoga who had just won an award for her work at that school. She talks about what an amazing experience the busing program has been for her family, and how her daughter loved being bused to Kennedy!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1997.4.20.busingschoolscores.pdf",
            label: "This article from 1997 is about busing and test scores, but it spends a lot of time talking about how the faculty and staff at Howard Kennedy went above and beyond to make every student and parent comfortable and happy during the busing process!"
        }
    ]
}
