module.exports = {
    url: "/archive/student-life",
    header: "Student Life",
    description: "This section of the archive is all about what life has been like for students at Howard Kennedy and the Omaha View School for over 100 years. It focuses on challenges the students have faced, activities they have been involved in, awards they have won, and so much more. Read on to find out what it was like to be a student at Kennedy starting with penmanship lessons in the 1880s and ending with the wifi buses of today! All of the articles in this section of the archive are organized in chronological order. That means the articles with the earliest dates--like those from the 1890s--will come first, and the articles with the most recent dates--like those from the 2010s--will come last. You may notice that some of the articles use words that are outdated. We no longer use words like these. Every article with a word like this has a label that suggests other words to use instead.",
    categories: [
        { name: 'disaster-strikes', img: '/Kennedy Archives/Pictures for Pages/Disaster Strikes.jpg' }, 
        { name: 'special-events', img: '/Kennedy Archives/Pictures for Pages/Special Events.png' }, 
        { name: 'student-activities', img: '/Kennedy Archives/Pictures for Pages/Student Activities.jpg' }, 
        { name: 'student-profiles', img: '/Kennedy Archives/Pictures for Pages/Student Profiles.jpg' }, 
        { name: 'student-successes', img: '/Kennedy Archives/Pictures for Pages/Student Successes.jpg' }, 
        { name: 'school-news', img: '/Kennedy Archives/Pictures for Pages/School News.jpg' }, 
        { name: 'integration', img: '/Kennedy Archives/Pictures for Pages/Integration.jpg' },
    ],
    labels: [
    ]
}
