import React from 'react';
import styled from 'styled-components';

import ScrollToTop from 'src/Components/ScrollToTop/index.js';

import Header from 'src/Components/Header/index.js';
import Content from 'src/Components/Content/index.js';
import Footer from 'src/Components/Footer/index.js';

import Contact from 'src/Pages/Contact/index.js';

import NotableFigures from 'src/Pages/NotableFigures/index.js';
import HowardKennedy from 'src/Pages/NotableFigures/HowardKennedy/index.js';
import MalcolmX from 'src/Pages/NotableFigures/MalcolmX/index.js';
import MildredBrown from 'src/Pages/NotableFigures/MildredBrown/index.js';

import DisasterStrikes from 'src/Pages/Archive/StudentLife/DisasterStrikes/index.js';
import Integration from 'src/Pages/Archive/StudentLife/Integration/index.js';
import SpecialEvents from 'src/Pages/Archive/StudentLife/SpecialEvents/index.js';

import Holidays from 'src/Pages/Archive/StudentLife/SpecialEvents/Holidays/index.js';
import OtherEvents from 'src/Pages/Archive/StudentLife/SpecialEvents/OtherEvents/index.js';
import Performances from 'src/Pages/Archive/StudentLife/SpecialEvents/Performances/index.js';
import Speakers from 'src/Pages/Archive/StudentLife/SpecialEvents/Speakers/index.js';

import StudentActivities from 'src/Pages/Archive/StudentLife/StudentActivities/index.js';
import AfterSchool from 'src/Pages/Archive/StudentLife/StudentActivities/AfterSchool/index.js';
import Athletics from 'src/Pages/Archive/StudentLife/StudentActivities/Athletics/index.js';
import Candids from 'src/Pages/Archive/StudentLife/StudentActivities/Candids/index.js';
import CharityWork from 'src/Pages/Archive/StudentLife/StudentActivities/CharityWork/index.js';
import Clubs from 'src/Pages/Archive/StudentLife/StudentActivities/Clubs/index.js';
import DayToDay from 'src/Pages/Archive/StudentLife/StudentActivities/DayToDay/index.js';
import ClassActivities from 'src/Pages/Archive/StudentLife/StudentActivities/DayToDay/ClassActivities/index.js';
import FieldTrips from 'src/Pages/Archive/StudentLife/StudentActivities/DayToDay/FieldTrips/index.js';
import PreSchoolAndHeadstart from 'src/Pages/Archive/StudentLife/StudentActivities/DayToDay/PreSchoolAndHeadstart/index.js';
import Music from 'src/Pages/Archive/StudentLife/StudentActivities/Music/index.js';
import SafetyPatrol from 'src/Pages/Archive/StudentLife/StudentActivities/SafetyPatrol/index.js';
import SummerPrograms from 'src/Pages/Archive/StudentLife/StudentActivities/SummerPrograms/index.js';

import StudentProfiles from 'src/Pages/Archive/StudentLife/StudentProfiles/index.js';
import Academics from 'src/Pages/Archive/StudentLife/StudentSuccesses/Academics/index.js';
import Graduation from 'src/Pages/Archive/StudentLife/StudentSuccesses/Graduation/index.js';
import Misc from 'src/Pages/Archive/StudentLife/StudentSuccesses/Misc/index.js';
import StudentSuccesses from 'src/Pages/Archive/StudentLife/StudentSuccesses/index.js';
import SchoolNews from 'src/Pages/Archive/StudentLife/SchoolNews/index.js';
import StudentLife from 'src/Pages/Archive/StudentLife/index.js';

import TheFutureOfKennedy from 'src/Pages/Archive/OldSchoolToNewSchool/TheFutureOfKennedy/index.js';
import HowardKennedyChanges from 'src/Pages/Archive/OldSchoolToNewSchool/HowardKennedyChanges/index.js';
import HowardKennedyOpening from 'src/Pages/Archive/OldSchoolToNewSchool/HowardKennedyOpening/index.js';
import OmahaViewDisrepair from 'src/Pages/Archive/OldSchoolToNewSchool/OmahaViewDisrepair/index.js';
import OldSchoolToNewSchool from 'src/Pages/Archive/OldSchoolToNewSchool/index.js';

import FamilyOfTheWeek from 'src/Pages/Archive/FeaturedColumns/FamilyOfTheWeek/index.js';
import AnastasiasAlmanac from 'src/Pages/Archive/FeaturedColumns/AnastasiasAlmanac/index.js';
import StudentLetters from 'src/Pages/Archive/FeaturedColumns/StudentLetters/index.js';
import FeaturedColumns from 'src/Pages/Archive/FeaturedColumns/index.js';

import FacultyAndStaff from 'src/Pages/Archive/FacultyAndStaff/index.js';
import Alumni from 'src/Pages/Archive/Alumni/index.js';
import Archive from 'src/Pages/Archive/index.js';
import WhatIsAnArchive from 'src/Pages/Archive/WhatIsAnArchive/index.js';

import Home from 'src/Pages/Home/index.js';
import About from 'src/Pages/About/index.js';
import Search from 'src/Pages/Search/index.js';

import { routes } from 'src/constants';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.scss';
import './styles/main.scss';

const ToTopButton = styled.button`
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: #2C3E50;
  color: #fff;
  cursor: pointer;
  padding: 0;

  justify-content: center;
  align-items: center;
`;

const UpArrowIcon = styled.i`
  margin: 0;
  padding: 0;
`;

function App() {
  window.onscroll = function() {scrollFunction()};

  // TODO: Replace with actual javascript scrolling?
  function scrollFunction() {
    const  mybutton = document.getElementById("scroll-to-top");

    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "flex";
    } else {
      mybutton.style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    window.scrollTo(0, 0);
  }

  return (
    <div className="App">
        <Router>
          <ScrollToTop />
          <Header />
          <Content>
              <Switch>
                <Route exact path={routes.HOME} component={Home} />

                <Route path={routes.ABOUT} component={About} />

                <Route path={routes.CONTACT} component={Contact} />

                <Route path={routes.SEARCH} component={Search} />

                <Route path={routes.WHAT_ARCHIVE} component={WhatIsAnArchive} />

                <Route path={routes.NOTABLE_FIGURES_HOWARD_KENNEDY} component={HowardKennedy} />
                <Route path={routes.NOTABLE_FIGURES_MALCOLM_X} component={MalcolmX} />
                <Route path={routes.NOTABLE_FIGURES_MILDRED_BROWN} component={MildredBrown} />
                <Route path={routes.NOTABLE_FIGURES} component={NotableFigures} />

                {/* TODO: PROGRAMMATICALLY GENERATE ARCHIVE SUB PAGES */}
                <Route path={routes.ARCHIVE_ALUMNI} component={Alumni} />

                <Route path={routes.ARCHIVE_SL_DISASTER} component={DisasterStrikes} />
                <Route path={routes.ARCHIVE_SL_INTEGRATION} component={Integration} />

                <Route path={routes.ARCHIVE_SL_SE_HOLIDAYS} component={Holidays} />
                <Route path={routes.ARCHIVE_SL_SE_OTHER} component={OtherEvents} />
                <Route path={routes.ARCHIVE_SL_SE_PERFORMANCES} component={Performances} />
                <Route path={routes.ARCHIVE_SL_SE_SPEAKERS} component={Speakers} />

                {/* root student-life special-events */}
                <Route path={routes.ARCHIVE_SL_SE} component={SpecialEvents} />

                <Route path={routes.ARCHIVE_SL_SA_AFTER_SCHOOL} component={AfterSchool} />
                <Route path={routes.ARCHIVE_SL_SA_ATHLETICS} component={Athletics} />
                <Route path={routes.ARCHIVE_SL_SA_CANDIDS} component={Candids} />
                <Route path={routes.ARCHIVE_SL_SA_CHARITY_WORK} component={CharityWork} />
                <Route path={routes.ARCHIVE_SL_SA_CLUBS} component={Clubs} />

                <Route path={routes.ARCHIVE_SL_SA_D2D_ACTIVITIES} component={ClassActivities} />
                <Route path={routes.ARCHIVE_SL_SA_D2D_FIELD_TRIPS} component={FieldTrips} />
                <Route path={routes.ARCHIVE_SL_SA_D2D_PRE_SCHOOL_AND_HEADSTART} component={PreSchoolAndHeadstart} />

                {/* root student-life student-activities day-to-day */}
                <Route path={routes.ARCHIVE_SL_SA_D2D} component={DayToDay} />

                <Route path={routes.ARCHIVE_SL_SA_MUSIC} component={Music} />
                <Route path={routes.ARCHIVE_SL_SA_SAFETY} component={SafetyPatrol} />
                <Route path={routes.ARCHIVE_SL_SA_SUMMER} component={SummerPrograms} />

                {/* root student-life student-activities */}
                <Route path={routes.ARCHIVE_SL_SA} component={StudentActivities} />

                <Route path={routes.ARCHIVE_SL_STUDENT_PROFILES} component={StudentProfiles} />
                <Route path={routes.ARCHIVE_SL_SS_MISC_VICTORIES} component={Misc} />
                <Route path={routes.ARCHIVE_SL_SS_GRADUATION} component={Graduation} />
                <Route path={routes.ARCHIVE_SL_SS_ACADEMICS} component={Academics} />
                <Route path={routes.ARCHIVE_SL_SS} component={StudentSuccesses} />
                <Route path={routes.ARCHIVE_SL_SCHOOL_NEWS} component={SchoolNews} />

                {/* root student-life */}
                <Route path={routes.ARCHIVE_SL} component={StudentLife} />

                <Route path={routes.ARCHIVE_OLD_SCHOOL_FUTURE} component={TheFutureOfKennedy} />
                <Route path={routes.ARCHIVE_OLD_SCHOOL_CHANGES} component={HowardKennedyChanges} />
                <Route path={routes.ARCHIVE_OLD_SCHOOL_OPENING} component={HowardKennedyOpening} />
                <Route path={routes.ARCHIVE_OLD_SCHOOL_DISREPAIR} component={OmahaViewDisrepair} />
                <Route path={routes.ARCHIVE_OLD_SCHOOL} component={OldSchoolToNewSchool} />

                <Route path={routes.ARCHIVE_FC_FAMILY_OF_THE_WEEK} component={FamilyOfTheWeek} />
                <Route path={routes.ARCHIVE_FC_ANASTASIAS_ALMANAC} component={AnastasiasAlmanac} />
                <Route path={routes.ARCHIVE_FC_STUDENT_LETTERS} component={StudentLetters} />
                <Route path={routes.ARCHIVE_FC} component={FeaturedColumns} />

                <Route path={routes.ARCHIVE_FACULTY_AND_STAFF} component={FacultyAndStaff} />
                {/* END TODO: PROGRAMMATICALLY GENERATE ARCHIVE SUB PAGES */}

                {/* root archive */}
                <Route path={routes.ARCHIVE} component={Archive} />
                
              </Switch>
          </Content>
          <Footer />
          <ToTopButton onClick={e => topFunction(e)} id="scroll-to-top" title="Go to top"><UpArrowIcon className="fa fa-chevron-up"></UpArrowIcon></ToTopButton>
        </Router>
    </div>
  );
}

export default App;
