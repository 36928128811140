import React from 'react';
import styled from 'styled-components'
import BannerContainer from './BannerContainer';

const OverlayContainer = styled.div`
  min-height: 275px;
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    justify-content: center; 
    align-items: center; 
    padding: 30px 0;
  }
`;

const OverlayContent = styled.div`
  padding: 10px 0;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center; 
  background: black;
  opacity: .8;

  margin: 10px;

  h1 {
    font-size: 2em;
    font-weight: bold;
    margin: 0;
    color: #DEA200;
  }

  h2 {
    font-size: 1em;
    margin: 0;
    color: #fff;
  }

  @media only screen and (min-width: 768px) {
    height: 50%;
    width: 90%;
    max-width: 1440px;
    padding: 15px;
  }

  @media only screen and (min-width: 1080px) {
    margin-top: -120px;

    h1 {
      font-size: 5em;
    }
    h2 {
      font-size: 3em;
    }
  }
`;

function Banner({hideContent=false, backgroundImage, backgroundPosition='center', marginTop='0'}) {
  return (
    <BannerContainer backgroundImage={backgroundImage} backgroundPosition={backgroundPosition} marginTop={marginTop}>
      <OverlayContainer>
        {!hideContent && <OverlayContent>
          <h1>Howard Kennedy History</h1>
          <h2>A Digital Archive</h2>
        </OverlayContent>}
      </OverlayContainer>
    </BannerContainer>
  );
}

export default Banner;
