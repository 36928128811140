module.exports = {
    url: "/archive/student-life/student-activities/clubs",
    header: "Clubs",
    description: "There are many clubs at Howard Kennedy today, such as the Girls Fun Run and the Board Game club. In fact, the students at Kennedy have always been able to join new and exciting clubs! Over the last 100 years, these have included gardening clubs, marbles teams, something called the Rainbow Club... The list goes on and on! Check out the articles below that address these clubs and many more that have existed at Kennedy!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1916.11.12.clubs.pdf",
            label: "This clipping from 1916 is about a group of girls from Howard Kennedy that called themselves the Rainbow Club. They went on a hike and roasted wienies!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.6.8.girlsreservecorps.pdf",
            label: "This article from 1919 is about the Omaha members of the Girl Reserves who were working on different activities to raise enough money for some of them to go to camp. The girls at Howard Kennedy were being paid by their principal to organize supplies. Girl Reserves was a club for building confidence and skills in girls--kind of like Girl Scouts! It was started by the Y.W.C.A."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.6.29.gardening.pdf",
            label: "This article from 1919 talks about the 500 children in garden clubs across Omaha. The club at Howard Kennedy had just begun learning about canning. Canning is the process of saving food for later by sealing it inside cans and jars."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.8.26.canning.pdf",
            label: "In this clipping from 1919, a canning competition at Central High School is described. Students from different grade schools, including Howard Kennedy, would present the can foods they had put together. Canning is the process of saving food for later by sealing it inside cans and jars."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1930.5.29.marbles.pdf",
            label: "This article from 1930 announces that a boy from Druid Hill had won a marbles competition. A boy from Howard Kennedy named Wilbur O'Neil had competed against him. Many children played marbles in their free time back then. This game involved knocking another player's marbles out of a circle on the ground."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1947.10.17.campfiregirls.pdf",
            label: "This notice from 1947 describes a meeting of the Campfire Girls at Howard Kennedy. The Camp Fire Girls was a group that taught young girls outdoor skills. Today, both girls and boys are allowed into the Campfire organization."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.1.20.G-RAY.pdf",
            label: "This article from 1948 announces the officers who had just been elected in the Gra-Y club. The officers from Howard Kennedy were Melvin McCaw, Wallace Pope, and Leroy Tyree. Gra-Y was a club that taught boys respect and other life skills."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.1.25.G-RAY.pdf",
            label: "This article from 1948 announces the start of a Gra-Y club at Howard Kennedy. A picture is shown of the club president, Melvin McCaw, with Kermit Hansen, an official from the World Herald organization. Gra-Y was a club that taught boys respect and other life skills."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1949.5.14.marbles.pdf",
            label: "This clipping from 1949 talks about the winners of a marbles competition. Harry Simms, a fifth grader at Kennedy, won third place. This game involved knocking another player's marbles out of a circle on the ground."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1949.12.18.campfiregirls.pdf",
            label: "This clipping from 1949 is about a dinner held for members of the Campfire Girls and their fathers to celebrate the end of a candy-selling fundraiser. It lists the girls who sold the most candy. Their prize was being named as members of the Candy Queen's court. A student from Kennedy, Sandra Sue Williams, was on the court! The Camp Fire Girls was a group that taught young girls outdoor skills."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1951.6.15.campfiregirls.pdf",
            label: "This clipping from 1951 includes a picture of some of the new Camp Fire Girls at Howard Kennedy. The Camp Fire Girls was a group that taught young girls outdoor skills."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1951.6.15.girlscouts.pdf",
            label: "This notice fro 1951 announces that Howard Kennedy's Girl Scouts had gone on a camping trip to Camp MaHa. While at camp, they cooked, sang songs, and went hiking."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957.5.19.marbles.pdf",
            label: "This article from 1957 announces that Larry Dughman, a student at Howard Kennedy, had just won a marbles tournament. His prizes were a trophy and a bicycle. Marbles is a game that involves knocking another player's marbles out of a circle on the ground."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1959.2.20.G-RAY.pdf",
            label: "This notice from 1959 announces that the Gra-Y club at Kennedy would soon be taking new members. It also explains the values of club members. Gra-Y was a club that taught boys respect and other life skills."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1960.4.10.juniorforesters.pdf",
            label: "In this article from 1960, the activities of the Junior Foresters are described and a student from Kennedy named Waletta Smith speaks out about trees. The Junior Foresters was a group made up of children all over Omaha who were interested in learning about and planting trees."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1970.5.22.boyscouts.pdf",
            label: "The students at Howard Kennedy have always cared about their neighborhood! In this article from 1970, Boy Scouts from Kennedy and two other schools spend time picking up trash and helping to plant gardens around their area."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2000.10.22.girlscouts.pdf",
            label: "This article from 2000 explains that the Girl Scouts and Brownies from Howard Kennedy helped others on a Saturday to clean up litter around Omaha."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2004.11.18.girlsandboysclub.pdf",
            label: "This clipping from 2004 shows a photo of two students from Howard Kennedy who were members of the North Omaha Boys and Girls Club. The students are a pair of sisters named Sha'Tasia and ShaT'eisa Gay."
        },
    ]
}
