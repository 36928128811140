module.exports = {
    url: "/notable-figures/howard-kennedy",
    header: "Howard Kennedy: Educator, Settler, Businessman",
    labels: [
        {
            pdf: "/Kennedy Archives/Notable Figures/HK/1910.2.8.namechangedecision.pdf",
            label: "This article from 1910 describes the meeting of the school board in which it was decided to rename the Omaha View School after Howard Kennedy."
        },
        {
            pdf: "/Kennedy Archives/Notable Figures/HK/kennedy.obit.pdf",
            label: "This article from 1905 is an obituary for Howard Kennedy."
        }
    ]
}
