import React from 'react';
import styled from 'styled-components';
import Labels from 'src/Components/Labels';
import Section from 'src/Components/Layout/Section/index.js';
import Breadcrumbs from 'src/Components/Breadcrumbs/index.js';
import messages from './messages';

const MalcolmXContainer = styled.div`
`;

// DUPLICATED
const CategoryImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 15px 30px;
`;

const NewsPaperLayout = styled.div`
  display: block;
  content: "";
  clear: both;
  margin-bottom: 60px;

  img.right { float: right; }
  img.left { float: left; }
`;

// const PDF = styled.object`
//     height: 500px;
//     margin-bottom: 30px;

//     @media only screen and (min-width: 768px) {
//         height: 500px;
//     }
// `;
// DUPLICATED

function MalcolmX() {
  return (
    <MalcolmXContainer>
      <Section>
        <Breadcrumbs route={messages.url}></Breadcrumbs>
        <h1>{messages.header}</h1>
        <p>Malcolm X didn’t attend Howard Kennedy, but he was born just a few blocks away from the school on May 19th, 1925. He and his family moved from Nebraska to Michigan when he was just a few months old to escape the prejudice and danger that they faced in Omaha. A racist group called the Ku Klux Klan was making verbal and physical threats against Malcolm’s father, Earl Little, who was preaching about the longed-for rights and independence of his fellow African Americans in North Omaha. Though the family left Nebraska for their safety, Earl Little would continue to speak publicly about the need for civil rights for Black people until the day that he died.</p>
        <NewsPaperLayout>
          <CategoryImage className="right" src="/Kennedy Archives/Notable Figures/MX/malcolm-x-9396195-1-402.jpg" />
          <p>Malcolm would follow in his father’s footsteps in his fight for Black independence, but would take these ideas even further. By the time he returned to Omaha in 1964, he had become a figure known worldwide for his work fighting for the rights of Black people, not just in the United States but in every other country as well. A self-educated Muslim minister, he believed in the importance of Black leadership and self-determination. When he visited Omaha as an adult, Charles Washington--local civil rights activist and former student at Howard Kennedy--was able to interview him for the Omaha Star. Washington felt that this moment was the highlight of his career.</p>
          <CategoryImage className="left" src="/Kennedy Archives/Notable Figures/MX/malcolmx.charleswashington.picture.1964.jpg" />
          <p>Malcolm X was assassinated just a year later, but North Omaha never forgot him. Rowena Moore, a local activist whose son Robert Rose taught at Kennedy, owned the plot of land where the house Malcolm was born in once stood. She and others in the community led the fight for a historical marker and memorial to be placed on the site. The historical marker was added to the land in 1987 and the Malcolm X Memorial Foundation opened right next to it in 2010. Both still stand today as a reminder that Malcolm X was born in a little house in North Omaha and grew up to change the world.</p>
          <p><i>Look below for more information about Malcolm X.</i></p>
        </NewsPaperLayout>
        <Labels labels={messages.labels}></Labels>
        {/*         
        <PDF data={`${BASE_URL}/Kennedy Archives/Notable Figures/MX/1979.6.23.Malcolm.RobertRoseTeacher.pdf#view=FitH`} type="application/pdf" />
        <PDF data={`${BASE_URL}Kennedy Archives/Notable Figures/MX/1990.5.17.Washington,Charles.pdf#view=FitH`} type="application/pdf" />
        <PDF data={`${BASE_URL}/Kennedy Archives/Notable Figures/MX/malcolmx.charleswashington.picture.1964.pdf#view=FitH`} type="application/pdf" />
        */}
        <a target="_blank" rel="noopener noreferrer" href="https://www.malcolmxfoundation.org/" >malcolmxfoundation.org</a>
      </Section>
    </MalcolmXContainer>
  );
}

export default MalcolmX;
