module.exports = {
    url: "/archive/featured-columns/family-of-the-week",
    header: "Family of the week",
    description: "For many years, the Omaha Star ran a column called “Family of the Week”. Each week, a picture of a different family in North Omaha would be printed, along with some words about what they were doing in the community. Sometimes the children in these families were students at Howard Kennedy! Each of the articles below are examples of Kennedy students showing up in the “Family of the Week” column!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1946.2.8.familyoftheweek.pdf",
            label: "This article is about the McCaw family. Two of the children in this family were attending Howard Kennedy when this was printed in 1946. Melvin was a third grader who liked airplanes and the violin. Valaria was an eighth grader who wrote poetry and played the piano."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1946.3.22.familyoftheweek.pdf",
            label: "This clipping from 1946 is about the Wheelers. Windsor Wheeler was a second grader at Kennedy when this was printed. He loved to draw and collect postcards."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1946.4.26.familyoftheweek.pdf",
            label: "The Williamson family are shown in this clipping from 1946. Marlene Williamson was in the fifth grade at Kennedy when this was printed. She was a Girl Scout and loved to draw and sing."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1946.6.28.familyoftheweek.pdf",
            label: "This article from 1946 is about the Dean family. The two daughters, Joyce and Sheila, were attending Kennedy when this was printed. Joyce was a sixth grader and Sheila was a third grader. Both girls loved music and riding their bicycles. "
        },
        {
            pdf: "Kennedy Archives/AllMedia/1946.9.6.familyoftheweek.pdf",
            label: "This clipping from 1946 is about the Murrells. The Murrells' only daughter, Kathryn Jean, was five years old when this photo was taken, and had just started school at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1947.1.24.familyoftheweek.pdf",
            label: "The Grizzards are shown in this clipping from 1947. All three Grizzard children may have attended Howard Kennedy. The column states that Shirley Grizzard was in the sixth grade and Phyllis Grizzard was in the eighth grade at the school. James Jr. may also have been a student at Kennedy because he was in the eighth grade like his sister, Phyllis."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.2.6.familyoftheweek.pdf",
            label: "This article from 1948 is about the Hatcher family. The Hatcher’s daughter, Reta Mae, was a fifth grader at Howard Kennedy when this was printed. She liked to sing, skate, and attend the movies."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1951.5.11.familyoftheweek.pdf",
            label: "This clipping from 1951 is about the Jacksons. When this article was printed, the Jacksons had two children at Kennedy. Margaret was 11 and played the piano. Master was 13 and was in three different clubs."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1951.11.30.familyoftheweek.pdf",
            label: "This article from 1951 is about the Diamonds and their granddaughter, Diane or Diana Cook. (The clipping says both Diane and Diana.) She was 6 years old when this was printed and was a first grader at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1975.12.31.familyoftheweek.pdf",
            label: "The Pinkards are shown in this clipping from 1975. They are a large family who sang and performed together. At the time this photo was taken the two youngest children, Kenneth and Jeffery, were both students at Howard Kennedy."
        },
    ]
}
