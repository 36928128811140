module.exports = {
    url: "/archive/student-life/school-news",
    header: "School News",
    description: "At different times from the 1800s to the 2000s, the Omaha Star and the Omaha World Herald have printed articles that give readers a peek into what day-to-day life at the Omaha View School and Howard Kennedy Elementary has been like. Take a look at these clippings to see how classes and activities have both changed and stayed the same in the last 100 years!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1889.6.16.notesonschool.pdf",
            label: "The person who wrote this 1889 article describes the view from the windows of the Omaha View School. Then they speak about the daily activities of the first, fifth, and seventh grade classrooms. The article also contains examples of the signatures of four of the students at the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1953.9.18.hkschoolnews.pdf",
            label: "This clipping from 1953 begins with the news that Howard Kennedy had the largest number of students attending in the history of the school. It then lists all the new teachers at Kennedy and addresses the new plans to give students extra responsibilities in the coming school year."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1956.9.14.hkschoolnews.pdf",
            label: "This article from 1956 talks about many of the things happening at Kennedy in September of 1956, including the buying of new social studies books, the hiring of new teachers, and football scores. The article also mentions that there were 822 students attending the school that year. How many students do you think go to Kennedy today?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1956.10.19.hkschoolnews.pdf",
            label: "This article from 1956 talks about two assemblies that took place at Kennedy. One was about fire safety and the other was given by the Junior Red Cross Council. It also talks about the building of the new addition to Howard Kennedy. This addition is the side of the school where the second and third grade classrooms are now. With over 800 students, the school really needed the space!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1956.11.2.hkschoolnews.pdf",
            label: "The first part of this article from 1956  is all about asking parents to make sure that their kids study hard and do well in school instead of listening to Elvis Presley records. Do you know who Elvis Presley was? The article also congratulates the Boy Scouts on their hard work and reports that Howard Kennedy has a winning flag football team. Vernon Breakfield, Gale Sayers, and Ardell Dunn are all named for playing well in the most recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.6.6.hkschoolnews.pdf",
            label: "This clipping from 1958 starts off with a call to those living near Howard Kennedy to keep their lawns looking beautiful. It goes on to talk about the dedication for the new addition to the school that had just been built. Then there is a long list of activities going on with the students and teachers at Kennedy. With 900 students, the school was doing well but was still much more crowded than it is today!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1959.8.28.hkschoolnews.pdf",
            label: "This article from 1959 is all about back-to-school news! It first speaks about the hard work that the custodians have been doing all summer to keep the school clean and shiny. Then the writer talks about the new teachers who have joined the staff. Finally, the article gives instructions for all students returning to Howard Kennedy and those who will be attending the school for the first time."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1966.4.8.hkschoolnews.pdf",
            label: "This clipping from 1966 tells parents about how they can register their children for kindergarten. It spends a lot of time talking about Howard Kennedy’s extended day program, which was a program that gave extra classes after school ended for students who wanted to learn even more than they did during the regular school day! These classes were art, music, math, and reading. Kennedy’s Safety Patrol was also addressed because they had just been congratulated by the Omaha Police Department for their great work!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.10.3.hkschoolnews.pdf",
            label: "This clipping from 1974 is full of photos taken at Kennedy! They include a picture of two Safety Patrol officers, a photo of the Student Council, and four other pictures of students and teachers."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2003.5.15.hkschoolnews.pdf",
            label: "This article from 2003 is all about student activities at Kennedy! It talks about students going to a science fair, an Earth Day celebration, and fifth graders studying the rainforest!"
        },
    ]
}
