import React from 'react';
import styled from 'styled-components';
import messages from './messages';
import Labels from 'src/Components/Labels';
import Section from 'src/Components/Layout/Section/index.js';
import Breadcrumbs from 'src/Components/Breadcrumbs/index.js';

const HowardKennedyContainer = styled.div``;

// DUPLICATED
const CategoryImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 15px 30px;
`;

const NewsPaperLayout = styled.div`
  display: block;
  content: "";
  clear: both;
  margin-bottom: 60px;

  img.right { float: right; }
  img.left { float: left; }
`;
// DUPLICATED

function HowardKennedy() {
  return (
    <HowardKennedyContainer>
      <Section>
        <Breadcrumbs route={messages.url}></Breadcrumbs>
        <h1>{messages.header}</h1>
        <p>When you search for “Howard Kennedy” on Google, you mainly get results about the school. But where did that name come from? Who was the original Howard Kennedy?</p>
        <NewsPaperLayout>
          <CategoryImage className="right" src="/Kennedy Archives/AllMedia/pic.Kennedy5.jpg" />
          <p>He was in Omaha almost from the beginning. Originally from New York, he moved to Omaha in 1859--just five years after the city was founded. That same year, he was hired as the superintendent for public schools in Omaha. He had worked as a teacher before, and used that experience to get to work right away, opening Omaha’s first public school. Today, the superintendent of OPS is in charge of making sure that all the schools in the district are running smoothly by attending meetings and making big decisions. But back in 1859, Howard Kennedy had to teach the students himself, with the help of four other teachers.</p>
          <CategoryImage className="left" src="/Kennedy Archives/AllMedia/Howard.Kennedy.1859.jpg" />
          <p>Later on, when he was no longer teaching, he served as both president and vice president of the school board for 10 years--continuing his involvement with the district. He also worked for the government and the Union Position dealing with land. This was an important business in Nebraska’s early years as a state. Deciding how land would be managed was complicated and often upsetting work. He lived a long life and his descendants include prominent Omaha residents--all the way down to Howard Kennedy IV!</p>
          <p>Howard Kennedy passed away in his home in 1905. A few years later, when the new Omaha View School building had just been finished, there was a discussion about renaming the school. Surely such an impressive and modern building deserved a new name. Many Omahans felt that instead of naming the school after a famous leader of the past such as George Washington, it would be more exciting to begin naming buildings and roads after local leaders. The name of Howard Kennedy--one of the original settlers of the city and a man who was dedicated to the public school system--was chosen. OPS has continued to name schools after important educators over the years. In fact, Skinner Magnet Center was named after Eugene Skinner, the first Black principal in OPS and a former student at Howard Kennedy Elementary School. It seems fitting that a local educator who had a school named after him attended a school named for an educator who came before him.</p>
          <p><i>Look below for more information about Howard Kennedy.</i></p>
        </NewsPaperLayout>
        <Labels labels={messages.labels}></Labels>
      </Section>
    </HowardKennedyContainer>
  );
}

export default HowardKennedy;
