module.exports = {
    url: "/archive/faculty-and-staff",
    header: "Faculty and Staff",
    description: "Kennedy has been lucky to have a long history of great educators. This section recognizes some of those incredible teachers and principals. Look closely to see if you can find any names you recognize! Please notice that some of these articles are obituaries. An obituary is an article in a newspaper that lets everyone know that a person has died. To find out more about obituaries, take a look at the glossary of terms on the main Archive page. Also, this section is in alphabetical order. That means that it starts with objects about people with last names at the BEGINNING of the alphabet--like Breakfield--and ends with the articles and photographs about people with last names at the END of the alphabet--like Williams. You may notice that some of the articles use words that are outdated. We no longer use words like these. Every article with a word like this has a label that suggests other words to use instead.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1.1984.8.28.obit.ErdiceBaumgardnerTeacher.pdf",
            label: "This is article from 1984 is an obituary for <u><b>Erdice Baumgardner</b></u>. Ms. Baumgardner started her teaching career at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.1963.4.16.obit.GertrudeBookerTeacher.pdf",
            label: "This clipping from 1963 is an obituary for Gertrude Booker. Ms. Booker taught at Kennedy for ten years with her twin sister, Lucy Booker."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1981.5.9.GwenBreakfieldPara.pdf",
            label: "This article from 1981 shows a picture of Gwen Breakfield. Mrs. Breakfield has been a beloved Educational Assistant at Howard Kennedy for years, and her husband, Vernon Breakfield, was a former student at the school!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1981.1.21.ErnestBryantTeacher.pdf",
            label: "This 1981 clipping states that Ernest Bryant planned to run for City Council in Omaha. He was a former teacher at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/6.1967.1.5.WelcomeTBryantTeacher.pdf",
            label: "This article from 1967 is about Welcome T. Bryant who taught at Kennedy for ten years. He was working as the director of social services for OPS but he had just been named the director of the Men's Job Corps Center in Lincoln. Job Corps is a program that gives young adults educational opportunities and job training."
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.1970.8.25.WelcomeTBryantTeacher.pdf",
            label: "This article from 1967 is about Welcome T. Bryant who taught at Kennedy for ten years. He was about to become the director of civil rights for the Department of Transportation."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.1982.10.6.obit.WelcomTBryantTeacher.pdf",
            label: "This 1982 clipping is a note written by a man named Art Grossman about Welcome T. Bryant who had just died. Mr. Bryant was a teacher at Kennedy for ten years."
        },
        {
            pdf: "Kennedy Archives/AllMedia/10.1996.7.26.MelvinClancyTeacher.pdf",
            label: "This article from 1996 is about Melvin Clancy who started his career in education as a fifth grade teacher at Howard Kennedy. He had become the director of elementary education for OPS and was now joining the Nebraska Equal Opportunity Commission."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.1983.2.24.TravisDixonTeacher.pdf",
            label: "In this 1983 article, Eugene Skinner, a former student at Kennedy and the first African American principal in OPS, writes about the importance of Black history and Black people who made a difference. Specifically, he talks about Travis Dixon, who taught P.E. at Howard Kennedy and encouraged him to apply to Omaha Public Schools for a job."
        },
        {
            pdf: "Kennedy Archives/AllMedia/12.1925.5.16.FrancEatonPrincipal.pdf",
            label: "This article from 1925 is about Franc Eaton who was retiring as principal of Howard Kennedy after working for forty years in the district."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1992.4.27.BarbaraFeezelTeacher.pdf",
            label: "This article announces the winners of the Peter Kiewit Foundation's 1992 Nebraska Teacher Achievement Awards. Barbara Feezel, a first grade teacher at Kennedy who created The Parent Closet Library which lent materials to parents and children to make sure that parents were getting involved in their children's education."
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1989.3.27.obit.CecilieFormanTeacher.pdf",
            label: "This article from 1989 is about Cecilie Forman, a former teacher at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1957.5.2.AltonBGoode.Teacher.pdf",
            label: "This clipping from 1957 is about Lois Goode who had been teaching at Howard Kennedy for twelve years. Mrs. Goode had just been named Teacher of the Year by the Christian Women's Community Fellowship."
        },
        {
            pdf: "Kennedy Archives/AllMedia/6.1984.6.8.obit.LoisGoodeTeacher.pdf",
            label: "This notice from 1984 is an obituary for Lois Goode. She had taught at Kennedy for twenty-four years."
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.1973.10.19.obit.GraceGriffithTeacher.pdf",
            label: "This clipping from 1973 is about Grace Griffith who was a former principal of Howrad Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.1959.6.7.IoneHangerTeacher.pdf",
            label: "This 1959 article is about Ione Williams Hanger, a seventh grade teacher at Kennedy. She was about to go on a trip to Liberia. Liberia is a country in West Africa."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.1994.1.28.obit.IoneHangerTeacher.pdf",
            label: "This clipping from 1994 is an obituary for Ione Williams Hanger, a former teacher at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/10.2013.7.22.BetsyHoeferteacher.pdf",
            label: "This article from 2013 is about Betsy Hoefer, a summer school teacher at Howard Kennedy who took a trip to the refugee camps in Thailand to teach English and connect more with her students from Myanmar."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.1992.10.15.TishaHollandTeacher.pdf",
            label: "This 1992 article talks about Tisha Holland who became a school counselor after teaching first grade at Howard Kenndy. She had just been given an award by the Nebraska Association for Counseling and Development. Can you think of another first grade teacher who became a school counselor?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/13.1970.8.3.obit.EHJonesTeacher.pdf",
            label: "This article from 1970 is about E. H. Jones who taught at Kennedy from 1937 to 1960. She earned a degree in education at the age of sixty-six after thirteen years of summer and night classes."
        },
        {
            pdf: "Kennedy Archives/AllMedia/14.1960.6.17.LulaJonesTeacher.pdf",
            label: "This 1960 clipping is about Lula Jones. Mrs. Jones was retiring after teaching kindergarten at Kennedy for seventeen years. A photo is shown of every student she had taught who was still attending the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/14b.1960.7.9.LulaJonesTeacher.pdf",
            label: "This article is about the retirement of Lula Jones who had taught kindergarten at Kennedy for seventeen years. She was sad to leave the school, but had to do so because at that time, when teachers in the district turned 70, they had to retire."
        },
        {
            pdf: "Kennedy Archives/AllMedia/16.1979.10.4.DeniseKingTeacher.pdf",
            label: "This article from 1979 announces that Denise King, a former teacher at Howard Kennedy, had just earned a top job with a group called the National Coalition of Advocates for Students. She was fighting for the rights of children of color and children from low-income families."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.1987.1.8.obit.HelenLambertTeacher.pdf",
            label: "This clipping from 1987 is an obituary for Helen Lambert, who taught at Howard Kennedy for twenty years."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.2009.5.14.MaryLehrTeacher.pdf",
            label: "In this article from 2009, it is announced that Mary Lehr, a teacher at Kennedy, had won over $500 from a U.S. Cellular contest to spend on classroom supplies."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1984.9.8.obit.LucyLoppnowTeacher.pdf",
            label: "This notice from 1984 is an obituary for Lucy Loppnow, a former teacher at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1983.4.28.FlorenceMaxwellLibrarian.pdf",
            label: "This short notice from 1983 announces a retirement party for two teachers. One of these teachers was Florence Maxwell, who was a librarian at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/6.1977.5.19.TheodoraMeadowsTeacher.pdf",
            label: "This article from 1977 is about Theodora Meadows, a former teacher at Howard Kennedy who had just been listed in the 1977-1978 edition of the book “Who’s Who of American Women.”"
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.2008.2.7.BerthaMeyersTeacher.pdf",
            label: "This article from 2008 is about Bertha Meyers, a musician who started her teaching career in OPS as a subsitute music teacher at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.2011.3.1.obit.BerthaMeyersTeacher.pdf",
            label: "This 2011 article is an obituary for Bertha Meyers, a musician who started her teaching career in OPS as a subsitute music teacher at Howard Kennedy. She taught music for many years and would become the first Black guidance counselor at Central High School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.1996.1.9.obit.EmeraldMitchellTeacher.pdf",
            label: "This article from 1996 is an obituary for Emerald Mitchell, a former first grade teacher at Howard Kennedy."
        },

        {
            pdf: "Kennedy Archives/AllMedia/JuanitaMooreOutside.faculty.staff.png",
            label: "This is a photograph of Juanita Moore who served as principal at Howard Kennedy from 1968 to 1983. (1)"
        },
        {
            pdf: "Kennedy Archives/AllMedia/Juanita.Moore0001.faculty.staff.png",
            label: "This is a photograph of Juanita Moore who served as principal at Howard Kennedy from 1968 to 1983. (2)"
        },
        {
            pdf: "Kennedy Archives/AllMedia/JuanitaMoore0002.faculty.staff.png",
            label: "This is a photograph of Juanita Moore who served as principal at Howard Kennedy from 1968 to 1983. (3)"
        },
        {
            pdf: "Kennedy Archives/AllMedia/JuanitaMoore0003.faculty.staff.png",
            label: "This is a photograph of Juanita Moore who served as principal at Howard Kennedy from 1968 to 1983. (4)"
        },
        
        {
            pdf: "Kennedy Archives/AllMedia/10.1972.8.24.JuanitaMoorePrincipal.pdf",
            label: "This article from 1972 announces that Juanita Moore would be the speaker at the St. John A.M.E. Church's Women's Day service. A former student at Kennedy, Mrs. Moore eventually became principal of the school!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/LibGaz_PatDickeyTopLeft.faculty.staff.png",
            label: "This is a photograph of the faculty and staff under the gazebo in the library on an unknown date. Principal Juanita Moore can be seen on the bottom right side of the photo."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.1981.2.26.JuanitaMoorePrincipal.pdf",
            label: "This clip from 1981 features African American administrators in OPS. A picture is shown of Juanita Moore, the principal at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/12.1983.5.19.JuanitaMoorePrincipal.pdf",
            label: "This 1983 article announces the retirment of Juanita Moore, a former Kennedy student who was principal of the school for fifteen years."
        },
        {
            pdf: "Kennedy Archives/AllMedia/13.2019.4.15.JuanitaMoorePrincipal.pdf",
            label: "This article from 2019 celebrates the 100th birthday of Juanita Moore, a former student and principal of Howard Kennedy who has given much to her family and community."
        },
        {
            pdf: "Kennedy Archives/AllMedia/14.1913.10.29.MabelMouldTeacher.pdf",
            label: "This 1913 article is about Mabel Mould, a teacher at Kennedy who planned to go live on her claim and turn it into a proper farm. A claim back then was land owned by the governemnt that a person or group declared to be their own. It was kind of like finders keepers!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/15.1944.12.10.DaniztaObrodovichTeacher.pdf",
            label: "This article is about Danizta Obrodovich, a former teacher at Kennedy. At the time that this was printed in 1944, Obrodovich was in the Women's Army Corps, doing her part for America's fight in World War II."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.1984.12.13.HelenPattersonTeacher.pdf",
            label: "This clipping from 1984 announces that Helen Patterson, a former teacher at Howard Kennedy, had been elected as the president of the Urban League Guild of Nebraska. The Urban League Guild is a volunteer group that supports the work of the Urban League--a civil rights organization."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2.1993.7.1.HelenPattersonTeacher.pdf",
            label: "This 1993 clipping announces the retirement of Helen Patterson from Omaha Public Schools. Mrs. Patterson had started her career at Howard Kennedy. She spoke about how things had changed since she first began working for the district."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1951.8.31.obit.EffieReedTeacher.pdf",
            label: "This article from 1951 is an obituary for Effie Reed, a former principal at Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/4.1943.9.10.RubyReeseNurseryTeacher.pdf",
            label: "This clipping from 1943 is about Ruby Reese, a volunteer nursery school teacher at Howard Kennedy. She was being honored for all her work volunteering with organizations to help the war effort during World War II."
        },
        {
            pdf: "Kennedy Archives/AllMedia/5.1892.8.18.NettieRhettTeacherInfluencer.pdf",
            label: "This notice is an advertisement for two doctors which features Nettie Rhett, a teacher at the Omaha View School. Maybe Mrs. Rhett would have been a good influencer if Instagram or TikTok had been around in 1892!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/7.2008.8.9.BobRoseTeacher.pdf",
            label: "This article from 2008 speaks about Bob Rose, a teacher at Howard Kennedy who coached youth football for years. A former student says that he was a positive role model for young Black children in the 1950s and 1960s. He showed them what a Black man could accomplish, and many of his students went on to be coaches and prominent business owners."
        },
        {
            pdf: "Kennedy Archives/AllMedia/8.1962.6.11.obit.ZellSahnPrincipal.pdf",
            label: "This notice from 1962 is an obituary for Zell Sahn. She had been the principal at Howard Kennedy for almost ten years when she died."
        },
        {
            pdf: "Kennedy Archives/AllMedia/9.1947.8.29.Skinners.familyoftheweek.pdf",
            label: "In this 1947 clipping of the Omaha Star's “Family of the Week” column, Linda Skinner, a first grade teacher at Kennedy is shown with her husband, Eugene Skinner, a former student of the school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/10.1981.5.10.LindaSkinnerPrincipal.pdf",
            label: "This article from 1981 is about Linda Skinner. Mrs. Skinner had taught first and second grade at Kennedy for 23 years and had spent 40 years in OPS in all. She had recently retired and had won an award for her service."
        },
        {
            pdf: "Kennedy Archives/AllMedia/11.1984.2.18.LindaSkinnerTeacher.pdf",
            label: "This article announces that Linda Skinner, a former teacher at Howard Kennedy, and her husband, Eugene Skinner, were about to have a scholarship started in their names. Any Black student graduating from an Omaha public school could apply for the scholarship."
        },
        {
            pdf: "Kennedy Archives/AllMedia/12.2016.5.27.LindaSkinnerTeacher.pdf",
            label: "This article from 2016 is an obituary for Linda Skinner. Mrs. Skinner, a former first and second grade teacher at Howard Kennedy, died at the age of 100."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1.1999.4.2.WarrenTaylorTeacher.pdf",
            label: "This article from 1999 is about Warren Taylor, the former principal at Lothrop who started his teaching career at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/3.1976.1.8.JohnWilliamsTeacher.pdf",
            label: "This 1976 article is about Dr. John Williams, the first Black music teacher in OPS, who started his career teaching music at Howard Kennedy."
        }
    ]
}
