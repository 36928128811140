import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import styled from 'styled-components';

import Nav from './nav';

import './header.scss';

const HeaderContainer = styled.div`
  display: flex;
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  min-height: 60px;
  height: 60px;
  min-width: 260px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  background-color: #1C252E;
  color: #fff;

  @media only screen and (min-width: 768px) {
    padding: 0 30px;
    min-height: 120px;
    height: 120px;
  }
`;

const HeaderContent = styled.div`
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // TODO: 
  // homepage...
  max-width: 1440px;
  // archive page
  // max-width: 1200px;
  margin: 0 auto;
`;

const LogoImage = styled.img`
  width: 50px;
  height: 50px;

  @media only screen and (min-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const SearchContainer = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }

  border-bottom: 1px solid #2C3E50;
  background: #fff;
  transition: top .3s ease-out;
  position: fixed;
  padding: 15px;
  height: 65px;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 99;
  
  &.open {
    transition: top .3s ease-in;
    width: 100%;
    top: 120px;
  }

  &.mobile {
    z-index: 101;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
  }
`;

const SearchContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const MobileMenuNav = styled.nav`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  visibility: hidden;

  &.pre-open {display: block;visibility: visible;}
`;

const InputGroup = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`;

const InputGroupAppend = styled.div`
  display: flex;
  flex: 1;
  margin-left: -1px
`;

const SearchInput = styled.input`
  border-radius: 4px 0 0 4px;
  border-radius-top-right: 0;
  border-radius-bottom-right: 0;
  width: 100%;
  height: 35px;

  &:focus {
    outline: none;
  }
`;

const SearchButton = styled.button`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;

  height: 35px;
  border-radius: 0 4px 4px 0;

  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;

  cursor: pointer;
`;

function Header({history}) {
  var resizeId;
  var searchInputRef = React.createRef();;
  const [mobileSearchIsOpen, setMobileSearchIsOpen] = useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [preShowMobileMenu, setPreShowMobileMenu] = useState(false);
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (window.previousLocation && window.previousLocation.pathname !== history.location.pathname) {
      closeMenuAndSearch();
      window.previousLocation = history.location;
    } else if(!window.previousLocation) {
      window.previousLocation = history.location;
    }
  });

  const openSearchMenu = function() {
    const inpt = searchInputRef.current;
    setSearchMenuIsOpen(!searchMenuIsOpen);
    setTimeout(function(){ inpt.focus(); }, 200);
  };

  const search = function() {
    history.push(`/search?${searchValue}`);
    closeMenuAndSearch();
  }

  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      search(e);
    }
  }

  const openMobileMenu = function() {
    if (mobileMenuIsOpen) {
      setMobileMenuIsOpen(!mobileMenuIsOpen)
      setTimeout(() => {setPreShowMobileMenu(!preShowMobileMenu)}, 500);
    } else {
      setPreShowMobileMenu(!preShowMobileMenu);
      setTimeout(() => {setMobileMenuIsOpen(!mobileMenuIsOpen)}, 200);
    }
  }

  const animateMobileMenuSearch = function() {
    const inpt = searchInputRef.current;
    setMobileSearchIsOpen(!mobileSearchIsOpen);
    setTimeout(function(){ inpt.focus(); }, 200);
  }

  let currentWidth = window.innerWidth;
  window.addEventListener('resize', function() {
      clearTimeout(resizeId);
      resizeId = setTimeout(doneResizing, 500);
  });

  function doneResizing(){
    if (currentWidth !== window.innerWidth) {
      closeMenuAndSearch();
    }
    currentWidth = window.innerWidth;
  }

  function closeMenuAndSearch() {
    setMobileSearchIsOpen(false);
    setSearchMenuIsOpen(false);
    setMobileMenuIsOpen(false);
    setPreShowMobileMenu(false);
  }

  return (
    <HeaderContainer>
      <SearchContainer className={`${searchMenuIsOpen || mobileSearchIsOpen ? 'open' : ''} ${mobileSearchIsOpen ? 'mobile' : ''}`}>
        <SearchContent>
          <InputGroup>
            <InputGroupAppend>
              <SearchInput aria-label="search" type="text" ref={searchInputRef} placeholder="Search" onKeyDown={e => handleKeyDown(e)} value={searchValue} onChange={e => setSearchValue(e.target.value)} />
            </InputGroupAppend>
            <SearchButton type="button" onClick={e => search(e)}>Search</SearchButton>
          </InputGroup>
        </SearchContent>
      </SearchContainer>
      <HeaderContainer>
        <HeaderContent>
          <Link to="/" aria-label="Logo"><LogoImage alt="logo" src="/logo.svg" /></Link>
          <span className={`hamburger ${mobileMenuIsOpen ? 'open' : ''} ${mobileSearchIsOpen ? 'hide' : ''}`} onClick={() => {openMobileMenu()}} aria-expanded={mobileMenuIsOpen}>
            <span className="lines"></span>
          </span>
          <span className={`mobile-search ${mobileMenuIsOpen ? 'open' : ''} ${mobileSearchIsOpen ? 'hide' : ''}`} onClick={() => {animateMobileMenuSearch()}}>
            <i className="fa fa-search"></i>
          </span>
          <MobileMenuNav className={`${preShowMobileMenu ? 'pre-open' : ''} ${mobileMenuIsOpen ? 'open' : ''}`} role="navigation">
            <Nav className="mobile-menu" closeMenuAndSearch={closeMenuAndSearch} />
            <div className="splash "></div>
          </MobileMenuNav>
            <Nav className="desktop-menu" openSearchMenu={openSearchMenu} />
          </HeaderContent>
      </HeaderContainer>
    </HeaderContainer>
  );
}

export default withRouter(Header);

/** 
 * TODO: 
 * FIX THE lack of <nav> for desktop/mobile
 * 
 * Possibly clean up desktop and mobile nav/menu solution
*/