module.exports = {
    url: "/archive/student-life/student-profiles",
    header: "Student Profiles",
    description: "The students in these articles made the newspaper! Scroll on to see some personal stories of students at Kennedy WHILE they were still students!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1939.7.7.art.pdf",
            label: "This article form 1939 is about a student at Howard Kennedy named Frank Paris. Frank was in a car accident and where he fractured his skull, and when he came back to school he had trouble focusing. The school counselor got him interested in art as a way to make him feel better, and that is how he found his passion for watercolors!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1942.7.13.studentprofile.pdf",
            label: "In 1942, this Kennedy student, Cleo Harmon, was about to spend her seventh birthday in the hospital after many surgeries. But she was keeping her head up and worked hard not to miss a day of school!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1943.9.17.studentprofile.pdf",
            label: "This notice from 1943 is about a brother and sister who went to Kennedy. Third grader Robert Rivers and kindergartener Lois Jean Rivers had just had their tonsils taken out!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1946.10.30.studenttravelsabroad.pdf",
            label: "This article from 1946 talks about a Kennedy student who was going to be on the radio explaining her trip out of the United States. Helen McMillan had traveled to West Africa!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1946.12.14.studentprofile.pdf",
            label: "This clipping from 1946 is also about a sister and brother. It explains that three-year-old Donnie Moore missed his sister Lauretta Moore every day when she went off to kindergarten at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.1.30.studentprofile.pdf",
            label: "This 1948 clipping describes Jo Ella Louese-Haynes--a kindergartener at Kennedy. Read on to find out Jo Ella's favorite hobbies at the time!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.12.7.studentprofile.pdf",
            label: "This article from 1958 contains a photograph of Howard Kennedy third grader, Keith McGill, who had to be in the hospital as he worked to get better after having polio. Polio is a disease which causes people to be unable to move parts of their body like their arms or legs. Because shots exist that stop people from getting the disease, nobody in the United States has gotten polio since the 1970s."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1964.5.10.studentprofile.pdf",
            label: "This 1964 article shows a picture of a six-year-old Kennedy student named Mitchell Pinkard. Mitch was in the hospital with a broken leg after getting hit by a car, but the popsicles he was eating were making him much happier!"
        }
    ]
}
