module.exports = {
    url: "/archive/student-life/special-events",
    header: "Special Events",
    description: "There’s always something happening at Kennedy! This part of the archive brings together descriptions and photographs of celebrations, performances, parades, visiting speakers, and many more events.",
    categories: [
        { name: 'holidays', img: '/Kennedy Archives/Pictures for Pages/Holidays.jpg' }, 
        { name: 'performances', img: '/Kennedy Archives/Pictures for Pages/Performances.jpg' }, 
        { name: 'speakers', img: '/Kennedy Archives/Pictures for Pages/Speakers.png' },
        { name: 'other-events', img: '/Kennedy Archives/Pictures for Pages/Other Events.png' }, 
    ],
    labels: [
    ]
}
