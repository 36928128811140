import React from 'react';
import styled from 'styled-components';
import Banner from 'src/Components/Banner/index.js';
import Timeline from 'src/Components/Timeline';
import Section from 'src/Components/Layout/Section/index.js';

const HomeContainer = styled.div`
`;

const HomeHeading = styled.h2`
  margin-top: 0;
  margin-bottom: 60px;
`;

function Home() {
  return (
    <HomeContainer>
      <Banner backgroundImage="/classroom.jpg"></Banner>
      <Section marginTop="60">
        <HomeHeading>Welcome to Howard Kennedy History: A Digital Archive! Here you will find pictures, newspaper articles, and other objects that tell stories about Kennedy over the years. Scroll and click to go on a journey back through time.</HomeHeading>
        <Timeline></Timeline>
      </Section>
    </HomeContainer>
  );
}

export default Home;
