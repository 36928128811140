import React, { useState } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import styled from 'styled-components';
import messages from './messages';
import ArchiveLayout from 'src/Components/Layout/ArchiveLayout';
import Categories from 'src/Components/Categories';
import Paragraphs from 'src/Components/Paragraphs';

const ArchiveContainer = styled.div`
  // margin-top: 30px;
  // max-width: 1200px;
  // margin: 0 auto;

  // padding: 0 5px;

  // @media only screen and (min-width: 768px) {
  //   margin-top: 60px;
  // }

  // h1 {
  //   margin-top: 0px;
  // }
`;

const Glossary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 60px;
`;

const GlossaryTerm = styled.div`
  margin-bottom: 15px;
`;

const GlossarySearch = styled.input`
  margin-bottom: 15px;
`;

const Term = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

function Archive({location}) {
  const [filter, setFilter] = useState('');

  if (!location.state) {
    location.state = {};
  }
  
  // const { glossary } = location.state;
  
  // useEffect(() => {
  //   if (glossary) {
  //     goToAnchor('glossary', glossary);
  //   } else {
  //     goToTop();
  //   }
  // });

  function getGlossary() {
    const terms = Object.keys(messages.glossary);
    return terms.filter(term => term.match(filter) != null).map(item => {
      return <GlossaryTerm key={item}>
        <Term>{item}</Term>: {messages.glossary[item]}
      </GlossaryTerm>
    });
  };

  return (
    <ArchiveContainer>
      <ArchiveLayout route={messages.url}>
        <h1>{messages.header}</h1>
        <Paragraphs paragraphs={messages.paragraphs} size="lg"></Paragraphs>
        <h2>Who uses archives?</h2>
        <Categories categories={messages.categories}></Categories>
        <Paragraphs paragraphs={messages.paragraphs2} size="lg"></Paragraphs>
        <ScrollableAnchor id="glossary">
          <Glossary id="glossary">
            <h1>{messages.glossaryHeader}</h1>
            <GlossarySearch type="text" placeholder="Glossary Filter" onChange={e => setFilter(e.target.value)} value={filter} />
            {
              getGlossary()
            }
          </Glossary>
        </ScrollableAnchor>
      </ArchiveLayout>
    </ArchiveContainer>
  );
}

export default Archive;
