module.exports = {
    url: "/archive/student-life/student-activities/summer-programs",
    header: "Summer Programs",
    description: "This section is all about programs that have been held at Kennedy during the summertime to keepy the school's students busy, safe, and happy!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1958.6.24.summerpark.pdf",
            label: "This clipping from 1958 announces that the playground at Howard Kennedy would be supervised for the summer so that kids around the neighborhood could safely play there. A picture is shown of kids at the playground listening to storytime."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958.7.31.showwagon.pdf",
            label: "In this article from 1958, a reporter writes that it began raining in the middle of an outdoor Show Wagon performance at Kennedy! The Show Wagon was a moving stage that went from park to park every summer. Since Kennedy's playground was treated like a park during the summertime, the Show Wagon stopped there!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1959.7.14.showagon.pdf",
            label: "This short notice from 1959 describes a performance put on when the Show Wagon was at Howard Kennedy. The Show Wagon was a moving stage that went from park to park every summer. Since Kennedy's playground was treated like a park during the summertime, the Show Wagon stopped there!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1960.8.5.summerprogram.pdf",
            label: "This clipping from 1960 describes the activities that were going on over the summer at the supervised Howard Kennedy playground. It encourages parents to bring their kids there!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1962.8.3.summerprogram.pdf",
            label: "This article from 1962 describes the end-of-the-summer celebrations at Howard Kennedy's supervised playground. Two children were crowned king and queen of the playground--twelve-year-old John Fountain and eight-year-old Connie Kercheval."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2011.6.30.summerenrichment.pdf",
            label: "This clipping from 2011 shows a photograph of Gizela Kwlhangana, a fourth grader at Kennedy, learning the art of kumihimo during summer school. Kumihimo is Japanese braid-making."
        }
    ]
}
