module.exports = {
    url: "/archive/featured-columns",
    header: "Featured Columns",
    description: "A column is a section of a newspaper that shows up every week or even every day! All of these clippings are from weekly columns in which students from the Omaha View School and Howard Kennedy appeared. Read on to find out more about these kids who attended Kennedy from the 1800s all the way to the 1970s! All of the articles in this section of the archive are organized in chronological order. That means the articles with the earliest dates--like those from the 1900s--will come first, and the articles with the most recent dates--like those from the 1970s--will come last.",
    categories: [
        { name: 'family-of-the-week', img: '/Kennedy Archives/Pictures for Pages/Family Of The Week.jpg' },
        { name: 'anastasia\'s-almanac', img: '/Kennedy Archives/Pictures for Pages/Anastasias Almanac.jpg' },
        { name: 'student-letters', img: '/Kennedy Archives/Pictures for Pages/Student Letters.jpg'},
    ],
    labels: []
}
