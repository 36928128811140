import React from 'react';
import styled from 'styled-components';
// eslint-disable no-undef
const reg = new RegExp(/\.\w+$/, 'i');

const LabelsContainer = styled.div`

`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;

    text-align: center;

    margin-bottom: 120px;
`;

const Label = styled.p`
    margin: 15px;
    // font-weight: bold;
    // font-style: italic;
`;

const PDF = styled.object`
    width: 100%;
    height: 200px;

    @media only screen and (min-width: 768px) {
        height: 500px;
    }
`;

const IMG = styled.img`
    max-width: 100%;
`;

function Labels({ labels }) {
    function createMarkup(labelValue) {
        return {__html: labelValue};
    }

    // item.pdf -> isn't just pdfs.... XD
    function getMediaType(pathToMedia) {
        let match = pathToMedia.match(reg);
        let ret = match && match[0];
        return ret;
    }

    function getLabels() {
        return labels.map((item, index) => {
            return <LabelContainer key={`${index}-${item.pdf}`}>
                    {getMediaType(item.pdf) === '.pdf' && <PDF data={`${BASE_URL}${item.pdf}#view=Fit`} type="application/pdf" />}
                    {(getMediaType(item.pdf) === '.png' || getMediaType(item.pdf) === '.jpg') && <IMG alt="" src={`${BASE_URL}${item.pdf}`} />}
                    <Label dangerouslySetInnerHTML={createMarkup(item.label)} />
                    {getMediaType(item.pdf) === '.pdf' &&  <a target="_blank" rel="noopener noreferrer" href={`${BASE_URL}${item.pdf}`} >View Full PDF</a>}
                </LabelContainer>
        });
    };

    return (
        <LabelsContainer>
            {getLabels()}
        </LabelsContainer>
    );
}

export default Labels;
