module.exports = {
    url: "/archive/featured-columns/student-letters",
    header: "Student Letters",
    description: "Rex M. and Uncle Ross both had columns in the Omaha World Herald where they printed children’s stories and letters that kids wrote to them. This section of the archive is full of letters written to Rex M. and Uncle Ross by students who went to the Omaha View School and Howard Kennedy!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1902.3.23.rexm.pdf",
            label: "Lulu O’Connor, 8 years old, writes in 1902 to tell Rex M. about her dog named Shep who can do tricks. She also writes about owning cows, chickens, and geese!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1902.4.27.rexm.pdf",
            label: "12-year-old Grace Culver writes in 1902 about going to a picnic where it rained. She didn’t have enough money to get home, but a nice man paid the cost of her ride on a streetcar. A streetcar is like a bus that runs on rails like a train."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1902.6.1.rexm.pdf",
            label: "Marie Meyen writes in 1902 about playing the piano. She says that she plans to perform in a recital and that she writes her own music."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1902.6.29.rexm.pdf",
            label: "An 11-year-old boy named Conrad Meyen tells Rex M. in 1902 that he loves the stories and letters in his column. He writes about the trees in his yard, a trip to Florence Lake, and playing croquet with other boys. Croquet is a game played with different-colored balls and wire hoops."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1902.7.27.rexm.pdf",
            label: "Fifth grader Velma Messick writes in 1902 about her happiness that school is out. She talks about visiting her grandma and a trip her father took to Lead, South Dakota."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1902.8.10.rexm.pdf",
            label: "Gertrude Smith, 13 years old, writes in 1902 about how much she loves to draw. She drew a picture of Paul Revere on a horse which her teacher put on the board."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1902.8.17.rexm.pdf",
            label: "Third grader Tessie Peterson writes in 1902 about her siblings—Willie, Curtis, Robert, and Loretta. She says that she went to Lothrop before moving to the Omaha View School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1903.3.15.rexm.pdf",
            label: "Tessie Peterson writes again in 1903 to ask questions about her favorite characters in Rex M.’s stories. She also talks about celebrating Valentine’s Day at school!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1906.12.2.uncleross.pdf",
            label: "Robert Holgerson, a fourth grader, writes in 1906 to answer a riddle that he saw in Uncle Ross’s column. He talks about his brother’s four years on the battleship Oregon."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1906.12.9.uncleross.pdf",
            label: "In 1906, 11-year-old Eideline Herum answers Uncle Ross’s latest riddle with “Mount Hood”. She knew the answer because she had just learned about Mount Hood in school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1909.2.28.uncleross.pdf",
            label: "Fifth grader Evangeline Borcherding writes in 1909 about how crowded it is at the Omaha View School. She also speaks about going to church and answers Uncle Ross’s latest riddle."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1909.11.14.uncleross.pdf",
            label: "In 1909, Evangeline Borcherding, now in the sixth grade, writes to tell Uncle Ross a little more about her life. She talks about her siblings, her grandma’s farm, and her piano lessons."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.1.30.uncleross.pdf",
            label: "In 1910, Perry Borcherding tells Uncle Ross about all of his Christmas presents. He also writes about the opening of the new Omaha View School. He says that he wishes the school would be given a new name. As we know, in a few short months, the Omaha View School was renamed!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1911.3.19.uncleross.pdf",
            label: "Ronald Hadley writes in 1911 about his little dog, Mutt, and his little sister, Margaret. He also answers Uncle Ross’s latest riddle."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1911.6.11.uncleross.pdf",
            label: "In 1911, Ronald Hadley writes again—this time about how he has the mumps. The mumps is an illness that we don’t see around much today with modern medicine, but it could keep kids out of school for weeks! Ronald is upset because he thinks his absence will mean that he will lose his job as the water monitor. What do you think a water monitor does?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1915.4.18.uncleross.pdf",
            label: "In 1915, 9-year-old Florence Lynch answers Uncle Ross’s riddle and then writes about her pets. She has a canary named Bill and a dog named Buster."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1915.9.26.uncleross.pdf",
            label: "In 1915, Carrie Petersen, a seventh grader, answers Uncle Ross’s riddle. She also talks about her vacation in Arlington. She rode horses, peeled potatoes, and fed chickens."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1916.3.5.uncleross.pdf",
            label: "8-year-old Genevieve Thiehoff writes in 1916 to tell Uncle Ross about a bird that flew into her house. Her mother got the bird to fly back out by lighting a match and holding it outside the door!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1917.6.3.uncleross.pdf",
            label: "Myrtle Christensen writes in 1917 that she is the best writer at Howard Kennedy. She has won 5 awards!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1917.6.24.uncleross.pdf",
            label: "In 1917, Joseph Bengtson, 8 years old, writes about his day at the park. He ate ice cream and candy, and he fed the bears peanuts! We usually aren’t allowed to feed animals at the zoo today!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1918.7.28.uncleross.pdf",
            label: "Sixth grader Inez Battles writes in 1918 about all the animals that live near her home. There is a squirrel in her yard that she loves to watch eat grass."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.6.15.uncleross.pdf",
            label: "12-year-old Helen Cosgrove writes for the first time in 1919 to tell Uncle Ross about her pet canary, Dick. She also answers the weekly riddle."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1920.8.22.uncleross.pdf",
            label: "In 1920, Loyce King, a sixth grader, writes about her Sunday school picnic at Riverview Park. She is excited for school to start up again after vacation."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1920.9.4.uncleross.pdf",
            label: "Sixth grader Hattie Maxwell writes in 1920 about skating every night of her summer vacation. She promises to be kind to her friends and animals."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1920.11.7.uncleross.pdf",
            label: "In 1920, Loyce King writes again to say that she is going to see a program with her sister and her friends. She says that she saw Uncle Ross at her school when he was there to talk about the Humane Society. The Humane Society still rescues animals today!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1926.4.18.uncleross.pdf",
            label: "7-year-old Albert Weberg writes in 1926 to join Uncle Ross’s club where every member promises to be kind to animals. He doesn’t have any pets but he feeds the sparrows all winter long."
        },
    ]
}
