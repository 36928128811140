import React from 'react';
import styled from 'styled-components';
import TimelineData from './timeline';
// eslint-disable-next-line no-unused-vars
import styles from './index.scss';

const TimelineContainer = styled.div``;

const Date = styled.h2`
  color: #1c252e;
  margin-bottom: 5px;
`;

const Name = styled.h3`
  color: #1c252e;
  margin-top: 0px;
`;

const PDF = styled.object`
    height: 500px;
    width: 100%;

    @media only screen and (min-width: 768px) {
        height: 500px;
    }
`;

function Timeline() {
  const outputTimeline = function() {
    return TimelineData.timeline.map((item, index) => {
      const even = index % 2 === 0;
      const side = even ? 'left' : 'right';
      return <div key={`${item.date}-${index}`} className={`container ${side}`}>
        <div className="content">
          <Date>{item.date}</Date>
          <a target="_blank" rel="noopener noreferrer" href={`${BASE_URL}${item.pdf}`} >
            <Name>{item.name}</Name>
          </a>
          <PDF data={`${BASE_URL}${item.pdf}#view=FitH`} type="application/pdf" />
        </div>
      </div>
    });
  };

  return (
    <TimelineContainer>
      <div className="timeline">
        {outputTimeline()}
      </div>
    </TimelineContainer>
  );
}

export default Timeline;
