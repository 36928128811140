import React from 'react';
import ContentContainer from './ContentContainer';

function Content({children}) {
  return (
    <ContentContainer>
      {children}
    </ContentContainer>
  );
}

export default Content;
