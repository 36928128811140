import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { toTitleCase } from 'src/Utility/String';

const Breadcrumbs = styled.ol`
    display: block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    list-style: none;
  
    //   TODO: figure out breadcrumb and header spacing.
    // margin: 15px 0 30px 0;

    // @media only screen and (min-width: 768px) {
    //   margin-top: 0px;
    // }

    > li {
        display: inline-block;

        > a {
            text-decoration: none;
        }
    }
`;

function Crumbs({ route }) {
    function getBreadcrumbs() {
        if (route && route.length) {
            let buildEachCrumbPath = '/';

            return route.split('/').map((item, index, arr) => {
                let last = index === arr.length - 1;
                let first = index === 0;
                let title = toTitleCase(item);
                if (index === 1) {
                    buildEachCrumbPath += `${item}`;
                } else if (index !== 0) {
                    buildEachCrumbPath += `/${item}`;
                }

                return <li key={item}>
                    {first && <Link to={buildEachCrumbPath}>Home&nbsp;/&nbsp;</Link>}
                    {!first && !last && <Link to={buildEachCrumbPath}>{title}&nbsp;/&nbsp;</Link>}
                    {!first && last && <Link to={buildEachCrumbPath}>{title}</Link>}
                </li>
            });
        }
    }

    return <nav data-id="breadcrumbs"><Breadcrumbs>{getBreadcrumbs()}</Breadcrumbs></nav>;
}

export default Crumbs;
