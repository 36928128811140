module.exports = {
    url: "/archive/student-life/student-activities/day-to-day/pre-school-and-headstart",
    header: "Pre School And Headstart",
    description: "Howard Kennedy has been known for many years for its successful Head Start program. But did you know that the history of pre-school at Kennedy goes all the way back to the 1940s? Check out these articles to find out about how what was a WPA nursery school in 1942 would eventually turn into the brand new Early Learning Center that just opened!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1942.9.13.wpanurseryschool.pdf",
            label: "In this notice from 1942, it is announced that the WPA would be opening a new nursery school at Howard Kennedy. The WPA was a government agency that created new jobs and programs during the 1930s and 1940s."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1942.10.4.wpanurseryschool.pdf",
            label: "This notice is also about the opening of a WPA nursery at Howard Kennedy in 1942. It explains a little more about what would be going on at the nursery. The WPA was a government agency that created new jobs and programs during the 1930s and 1940s."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1942.10.12.wpanurseryschool.pdf",
            label: "This article from 1942 gives more details about the WPA nursery schools that were opening up at different Omaha schools, including at Kennedy. The WPA was a government agency that created new jobs and programs during the 1930s and 1940s."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1944.5.12.firstpreschoolstudent.pdf",
            label: "This clipping from 1944 describes a birthday party held at Kennedy for six-year-old Lois Jean Rivers. She was the first student ever to enroll in the nursery school that had opened at the school two years before."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1966.1.5.headstart.pdf",
            label: "This article from 1966 describes a party that members of Job Corps threw for the kids in Head Start at Howard Kennedy. Job Corps is a program that gives young adults educational opportunities and job training."
        },
        {
            pdf: "Kennedy Archives/AllMedia/2015.3.18.reading.pdf",
            label: "This article from 2015 is about a program called Real Men Read, which aims to bring more men into classrooms to read books to students. The goal is to give kids more male role models to look up to. In the article, a reporter describes the scene when the director of the program, Willie Hamilton, comes to read a book to a classroom of Head Start students at Kennedy."
        },
        {
        pdf: "Kennedy Archives/AllMedia/2019.2.3.earlylearningcenterconstruction.pdf",
            label: "This article from 2019 lists a number of buildings being constructed in Omaha. One of the buildings mentioned was the Early Learning Center that was being built by Kennedy. The Center opened in January of 2020 and the Head Start students and teachers who had been in the Howard Kennedy building have now moved to the new building. Kennedy just keeps growing!"
        }
    ]
}
