import styled from 'styled-components';

const BannerContainer = styled.div`
    flex: 1;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-top: ${props => `${props.marginTop}px` || "14px"};
    background-image: ${props => `url(${props.backgroundImage})` || ""};
    background-position: ${props => props.backgroundPosition};

    @media only screen and (min-width: 768px) {
        min-height: 600px;
    }

    @media only screen and (min-width: 1080px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export default BannerContainer;
