module.exports = {
    timeline: [
        {
            date: "1886",
            pdf: "Kennedy Archives/AllMedia/1889.6.16.notesonschool.pdf",
            name: "Omaha View Opening"
        },
        {
            date: "1892-1910",
            pdf: "Kennedy Archives/AllMedia/1908.10.23.cantbeusedonwindyday.pdf",
            name: "Omaha View School Disrepair"
        },
        {
            date: "1908-1910",
            pdf: "Kennedy Archives/AllMedia/1908.8.18.plansaccepted.pdf",
            name: "Kennedy Construction"
        },
        {
            date: "Feb. 1st 1910",
            pdf: "Kennedy Archives/AllMedia/1910.2.1.openingtoday.pdf",
            name: "Kennedy Opening"
        },
        {
            date: "May 1910",
            pdf: "Kennedy Archives/AllMedia/1910.5.31.namededication.pdf",
            name: "Kennedy Naming"
        },
        {
            date: "1920s",
            pdf: "Kennedy Archives/AllMedia/1932.10.16.safetypatrol.pdf",
            name: "Safety Patrol: established"
        },
        {
            date: "Sept. 1942",
            pdf: "Kennedy Archives/AllMedia/1942.10.4.wpanurseryschool.pdf",
            name: "Nursery School established"
        },
        {
            date: "Dec. 1957",
            pdf: "Kennedy Archives/AllMedia/1957.12.2.additionopening.pdf",
            name: "Addition to Kennedy"
        },
        {
            date: "Aug. 1976",
            pdf: "Kennedy Archives/AllMedia/1976.8.26.busingmap.pdf",
            name: "Integration"
        },
        {
            date: "Sept. 2004",
            pdf: "Kennedy Archives/AllMedia/2004.9.23.renovationdedication.pdf",
            name: "Kennedy Renovation"
        },
        {
            date: "2015-present",
            pdf: "Kennedy Archives/AllMedia/2015.4.7.kennedyimprovement.pdf",
            name: "75 North"
        },
    ]
}
