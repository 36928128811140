import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { toTitleCase } from 'src/Utility/String';

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media only screen and (min-width: 650px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    a:visited {
      color: #000;
    }

    > a,
    > div {
      display: flex;
      width: 30%;
      margin-right: 10px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 950px) {
    > a,
    > div {
      margin-right: 20px;
    }
  }
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 15px 0;

  @media only screen and (min-width: 650px) {
    margin: 0;
  }
`;

const CategoryImage = styled.img`
  width: 100%;
  max-width: 408px;
  max-height: 287px;
  object-fit: contain;
  &.pos-22 { object-position: 50% 22%; }
`;

const Anchor = styled(Link)`
  /* unvisited link */
  &:link {
    color: black;
    text-decoration: underline;
  }

  /* visited link */
  &:visited {
    color: black;
    text-decoration: underline;
  }

  /* mouse over link */
  &:hover {
    color: black;
    text-decoration: underline;
  }

  /* selected link */
  &:active {
    color: black;
    text-decoration: underline;
  }
`;

function Categories({route, categories, classes=''}) {
    function getArchiveCategories() {
        return categories.map((item) => {
          // , index, arr
            // const styles = {
            //   width: window.screen.width > 650 && arr.length > 6 ? '22%' : ''
            // };

            if (!route) {
              return <Category key={`${item.name.replace('\'', '')}`}>
                  <CategoryImage className={classes} src={item.img} />
                  <h2>{toTitleCase(item.name)}</h2>
              </Category>
            }

            // style={styles}
            return <Anchor key={`${item.name.replace('\'', '')}`} to={`${route}/${item.name.replace('\'', '')}`}>
                <Category>
                    <CategoryImage className={classes} src={item.img} />
                    <h2>{toTitleCase(item.name)}</h2>
                </Category>
            </Anchor>
        });
    }

  return (
    <CategoriesContainer>
        {getArchiveCategories()}
    </CategoriesContainer>
  );
}

export default Categories;
