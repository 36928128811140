module.exports = {
    url: '/what-is-an-archive',
    name: 'WhatIsAnArchive',
    header: "What is an archive?",
    glossaryHeader: "Glossary",
    paragraphs: [
        'This is a digital archive! Pronounced “are-kive,” an archive is a collection of documents from history that have to do with a certain place, group, or other subject. THIS archive is all about the history of Howard Kennedy Elementary School. Many archives are in libraries and other buildings where we can visit them in person. A DIGITAL archive is different because we can look at it online from our schools or our homes.',
        'What kinds of objects can you find in an archive? Usually, an archive contains objects that tell us something about the past. This archive is full of newspaper clippings and old photographs, but there are so many more things in our lives that can be put into an archive--like maps, art, reports, letters, or birthday cards! Can you think of any other things that might belong in an archive? Is there something that you have kept for a long time that is important to the story of your life?',
    ],
    paragraphs2: [
        'A person who takes care of an archive and adds objects to it is called an archivist (pronounced “ark-iv-ist”). Why do some objects get to be in an archive while others don’t? What would you put in YOUR archive?'
    ],
    categories: [
        { name: 'Librarians use archives to teach people about the past!', img: '/Kennedy Archives/AllMedia/librarian.jpg' },
        { name: 'Detectives use archives to solve crimes!', img: '/Kennedy Archives/AllMedia/detective.jpg' },
        { name: 'Doctors use archives to cure diseases!', img: '/Kennedy Archives/AllMedia/doctor.png' },
        { name: 'Reporters use archives to find the truth for their stories!', img: '/Kennedy Archives/AllMedia/journalist.png' },
        { name: 'Anybody can use an archive or create an archive of their own!', img: '/Kennedy Archives/AllMedia/who.png' }
    ],
    glossary: {
        "alphabetical order": "A way of organizing a list where the words in the list follow the order of the letters in the alphabet. For example, words that start with the letter “A” would be at the top of the list, while words that start with the letter “Z” would end up at the bottom.",
        "archive": "A collection of documents and other objects from history that have to do with a certain place, group, or other subject.",
        "article": "A piece of writing in a newspaper or magazine. Most of the articles on this website are from the Omaha Star and the Omaha World Herald.",
        "chronological order": "A way of organizing the dates of events in history. In chronological order, the dates from long ago would be listed first and the dates from just a few days ago would be listed last. For example, 1932 would go before 1933 and 2020 would come WAY after 1957!",
        "clipping": "An article (piece of writing) cut from a newspaper. Most of the clippings on this website are from the Omaha Star and the Omaha World Herald.",
        "column": "A vertical division of a page or text.",
        "miscellaneous": "A group of people, things, or events that don't have anything in common but don't belong in other groups, either. An example is a group of bugs who don't have anything in common but can't be put into other bug groups.",
        "notice": "An announcement about something, either written down or spoken out loud. A notice is usally put out to give people time to plan for an event.",
        "obituary": "A notice of a death, especially in a newspaper, typically including a brief biography of the person who has passed.",
        "objects": "An object usually means something that can be seen or touched. When the word “obects” is used on this website, it could be referring to photographs, articles, clippings, notices, or all of those things together. Of course, objects online can't be touched--but they sure can be seen!",
    },
    labels: []
}
