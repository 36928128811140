import styled from 'styled-components';

const ContentContainer = styled.div`
    margin-top: 60px; // default header height on mobile -> fixed header

    display: flex;
    flex: 1;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
        margin-top: 120px;
    }
`;

export default ContentContainer;
