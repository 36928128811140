module.exports = {
    url: "/archive/student-life/student-activities/athletics",
    header: "Athletics",
    description: "Sports have been an important part of the lives of Howard Kennedy students for over 100 years! The objects below include stories of students participating in football, basketball, baseball, soccer, track and field, and volleyball!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1910.2.6.athletics.pdf",
            label: "This short notice from 1910 states that athletics at Howard Kennedy were being organized by the Y.M.C.A. Who do you know who coaches sports at Kennedy today?"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.5.18.track.pdf",
            label: "This clipping from 1910 is about a track meet in 1910 that Kellom School won. But keep reading and you'll see that there were also some students from Howard Kennedy who placed in events!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.5.24.baseball.pdf",
            label: "This notice from 1910 reports that Kennedy lost a baseball game. Though they didn't win, at least they made the news!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1911.5.18.track.pdf",
            label: "This article from 1911 goes over the results of a track meet that Howard Kennedy competed in. One paragraph is about a boy from Kennedy named Glen Osborn who continued to compete even after he had hurt his ankle!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1911.11.25.longball.pdf",
            label: "This article from 1911 states that the older girls at Kennedy were playing a game called long ball at recess. The object of long ball was to keep a ball from touching the ground for as long as possible by hitting it with bats."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1913.1.5.track.pdf",
            label: "This article from 1913 announces that Howard Kennedy had just won the yearly all-school track meet! Kellom, who had won the year before, had to turn the trophy over to Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1920.10.27.soccer.pdf",
            label: "This short notice from 1920 states that Kennedy students had beaten the Dundee school in a soccer game the day before."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1921.4.26.baseball.pdf",
            label: "This clipping from 1921 lists the final scores from baseball games that had happened recently in the Omaha area. Look closely, and you'll see that Druid Hill defeated Howard Kennedy by six points."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1925.9.27.athletics.pdf",
            label: "In this article from 1925, it is announced that Howard Kennedy had just won an athletic competition against eleven other elementary schools."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1926.5.19.track.pdf",
            label: "This article from 1926 talks about Howard Kennedy's win of the yearly World-Herald Track Meet. 13,000 people attended the event!"
        },{
            pdf: "Kennedy Archives/AllMedia/1926.5.29.track.pdf",
            label: "This is another article from 1926 about Howard Kennedy's win of the yearly World-Herald Track Meet."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1927.6.5.track.pdf",
            label: "According to this article from 1927, Howard Kennedy won the World-Herald Track Meet for the second year in a row in 1927!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.2.13.basketball.pdf",
            label: "This article from 1948 describes a basketball game held by the Howard Kennedy Gra-Y Club at a Y.M.C.A. Long school beat Kennedy by five points. Gra-Y was a club that taught boys respect and other life skills."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.10.19.soccer.pdf",
            label: "This article states that Howard Kennedy won the North Omaha Grade School soccer championship fo 1948."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1948.11.12.soccer.pdf",
            label: "This article from 1948 announces that the team from Howard Kennedy was the winner of the city soccer championship for the second year running. It includes a picture of the winning team!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1952.5.29.track.pdf",
            label: "This notice from 1952 is about an eighth grader at Howard Kennedy named Charles Morris who had just set the record for a high jump during a track meet."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1952.6.1.track.pdf",
            label: "This clipping from 1952 includes a picture of Charles Morris, the Howard Kennedy eighth grader who set a record for the high jump."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1955.10.7.football.pdf",
            label: "This article from 1955 is about the Roberts football team made up of middle schoolers. Featured in the article are the Sayers brothers, Roger and Gale, who were important members of the team, and who were both students at Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1958%20Kennedy%20volleyball0001.jpg",
            label: "This is a picture of Kennedy students playing volleyball in the new gym in 1958."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1960.12.5.flagfootball.pdf",
            label: "This notice from 1960 announces that fifth and sixth grade boys from Howard Kennedy had just become city-wide flag football champions."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1962.1.30.athletics.pdf",
            label: "This article from 1962 is about Rodney Jackson, a 12-year-old student at Howard Kennedy. The reporter states that he is a great athlete, and is expected to play several sports in high school."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1971.3.7.basketball.pdf",
            label: "This notice from 1971 contains basketball scores and shows that Bryan beat Howard Kennedy by eleven points in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1971.12.19.basketball.pdf",
            label: "This notice from 1971 contains basketball scores and shows that Long forfeited to Howard Kennedy in a recent game."
        },

        {
            pdf: "Kennedy Archives/AllMedia/1972.1.9.basketball.pdf",
            label: "This notice from 1972 contains basketball scores and shows that Conestoga beat Howard Kennedy by three points in a recent game. (1)"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1972.12.3.basketball.pdf",
            label: "This notice from 1972 contains basketball scores and shows that Conestoga beat Howard Kennedy by three points in a recent game. (2)"
        },
        
        {
            pdf: "Kennedy Archives/AllMedia/1973.12.9.basketball.pdf",
            label: "This notice from 1973 contains basketball scores and shows that Central Park beat Howard Kennedy by one point in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.1.27.basketball.pdf",
            label: "This notice from 1974 contains basketball scores and shows that Howard Kennedy beat Conestoga by eight points in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.2.10.basketball.pdf",
            label: "This notice from 1974 contains basketball scores and shows that Howard Kennedy beat Mommouth Park by three points in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1974.2.17.basketball.pdf",
            label: "This notice from 1974 contains basketball scores and shows that Martin Luther King beat Howard Kennedy by four points in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1975.1.19.basketball.pdf",
            label: "This notice from 1975 contains basketball scores and shows that Howard Kennedy beat Conestoga by one point in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1975.2.10.basketball.pdf",
            label: "This notice from 1975 contains basketball scores and shows that Kellom beat Howard Kennedy by twenty-six points in a recent game."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1975.2.20.basketball.pdf",
            label: "This clipping from 1975 has photographs of every one of the Kellom Center Cub Leage basketball teams, including the team from Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1975.6.3.track.pdf",
            label: "This article is full of images from a track meet in 1975 where the North Omaha Striders Track Club did very well. Many members of this club were Kennedy students and appear in the pictures!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2003.10.30.basketballtournament.pdf",
            label: "This article from 2003 talks about a basketball tournament that students from Howard Kennedy were going to play in. The tournament was being put together by the Omaha Police Department."
        }
    ]
}
