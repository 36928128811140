module.exports = {
    url: "/archive/old-school-to-new-school/howard-kennedy-opening",
    header: "Howard Kennedy Contruction & Opening",
    description: "This section of the archive contains objects that tell the story of the building of the new Omaha View School, and how the decision was made to rename the school after the first superintendent of public schools in Omaha, Howard Kennedy.",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1908.3.29.buildingproject.pdf",
            label: "This article from 1908 lists all the buildings that are planned to begin construction this year. The list includes a plan for the new Omaha View School."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1908.5.5.locationchosen.pdf",
            label: "In this clipping from 1908, the location for the new Omaha View School is announced. It would be a few blocks north of the old building."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1908.5.7.nonamechange.pdf",
            label: "This short notice from 1908 states that though the Omaha View School is will be in a new building, the name of the school will stay the same. We all know that this didn't stay true for long!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1908.8.18.plansaccepted.pdf",
            label: "This clipping from 1908 talks about the plans for the new Omaha View School. It was decided that the new building would have sixteen rooms and be completely fireproof."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1908.9.26.contractorchosen.pdf",
            label: "In this article from 1908, it is stated that the school board has decided which contractor will be hired to build the new Omaha View School. The contractor they chose was W. H. Parrish."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1908.11.19.bidsoncontracts.pdf",
            label: "This article from 1908 is all about the school board's argument over who would be hired to do the plumbing and heating at the new Omaha View School. It takes a lot of people to build a school!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.1.1.openingdate.pdf",
            label: "This article from 1910 announces that the new Omaha View School building was almost completed, and that it would open at the start of the next month."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.1.26.inspection.pdf",
            label: "This clipping from 1910 states that several officials would be inspecting the new Omaha View School before it was ready to be opened the following week."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.1.30.openingmonday.pdf",
            label: "This article from 1910 announces the opening of the new Omaha View School that Monday. It was being opened earlier than had been planned because the old building had become so dangerous. The school still hadn't been rennamed."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.2.1.openingtoday.pdf",
            label: "This clipping from 1910 shows a picture of the new Omaha View School and announces that it is officially open."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.2.8.namechangedecision.pdf",
            label: "This article from 1910 states that the school board voted to rename the Omaha View School after the first superintendent of public schools in Omaha, Howard Kennedy."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.3.8.plaque.pdf",
            label: "At the end of this article from 1910 about news from the school board, there is a paragraph describing the ordering of official pieces of parchment (a fancy type of paper) recognizing the changing of the name of the Omaha View School to Howard Kennedy Elementary School. The papers would be given to the school and to Howard Kennedy's three children."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.5.31.namededication.pdf",
            label: "This notice from 1910 tells readers that the dedication of the newly-named Howard Kennedy School will take place that Saturday with several speakers."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1910.6.5.namededication.pdf",
            label: "In this clipping from 1910, the dedication of the new Howard Kennedy School building is described. There were many important speakers and several musical performances."
        }
    ]
}
