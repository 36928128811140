module.exports = {
    url: "/archive/student-life/student-activities/day-to-day/field-trips",
    header: "Field Trips",
    description: "Kennedy students often go on field trips to this day! Here are a few of those outings that made the news!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1964.11.18.fieldtrip.pdf",
            label: "This article from 1964 is about a field trip that second and fifth graders from Howard Kennedy took to the Captain Meriwether Lewis dredge on the Missouri River. A dredge is a boat that digs up mud an other objects from deep under water."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1972.4.29.fieldtrip.pdf",
            label: "This clipping from 1972 shows a photograph of students from Kennedy on their field trip to Strategic Air Command at Offut Air Force Base. Strategic Air Command was a part of the military that was important in stopping a nuclear war from starting during the Cold War. Students at Kennedy today can learn all about it on their own field trips to the SAC Museum in Ashland!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1978.5.16.fieldtrip.pdf",
            label: "In this article from 1978, there is a photograph of five kindergarteners from Howard Kennedy petting a goat on their field trip to the zoo!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1978.5.16.fieldtrip.pdf",
            label: "This notice from 1978 is about a field trip that third and fifth graders from Howard Kennedy took to see a pipe organ. A pipe organ is a musical instrument that with keyboards that, when pressed, send air through large pipes, producing loud notes. Musicians who play the pipe organ use their hands AND their feet!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/2016.10.8.fieldtrip.pdf",
            label: "This article from 2016 describes events that were happening at different schools in Nebraska. It states that students from Howard Kennedy got to take a field trip to Prospect Hill Cemetary for the yearly tree planting there."
        },
    ]
}
