import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from 'src/Components/Breadcrumbs/index.js';

const ArchiveContainer = styled.div`
  margin-top: 30px;
  max-width: 1200px;
  margin: 0 auto;

  padding: 0 15px;
`;

const Header = styled.h1``;

const Description = styled.p`
  margin: 0 0 60px 0;
`;

function Layout({header, description, route, children}) {
  return (
    <ArchiveContainer>
        {route && <Breadcrumbs route={route}></Breadcrumbs>}
        {header && <Header>{header}</Header>}
        {description && <Description>{description}</Description>}
        {children}
    </ArchiveContainer>
  );
}

export default Layout;
