module.exports = {
    url: "/notable-figures/mildred-brown",
    header: "Mildred Brown: Newspaper Founder, Civil Rights Activist, Journalist",
    labels: [
        {
            pdf: "/Kennedy Archives/Notable Figures/MB/News_Article__Omaha_Star__November_9_1989__p1.pdf",
            label: "This article from 1989 is an obituary for Mildred Brown."
        },
        {
            pdf: "/Kennedy Archives/Notable Figures/MB/News_Article__Omaha_Star__November_9_1989__p1 (1).pdf",
            label: "This article from 1989 is another obituary for Mildred Brown."
        }
    ]
}
