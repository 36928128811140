module.exports = {
    url: "/archive/student-life/student-successes/miscellaneous-victories",
    header: "Miscellaneous Victories",
    description: "Miscellaneous is a word that is used to describe a group of things that don't seem to belong together but don't seem belong anywhere else, either. So the student successes in this section are miscellaneous because they are from all so different but have come together anyway!",
    labels: [
        {
            pdf: "Kennedy Archives/AllMedia/1916.5.12.firedrill.pdf",
            label: "This notice from 1916 describes a fire drill at Kennedy when all the students made it out of the building in just forty-two seconds!"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1918.8.30.canning.pdf",
            label: "The headline of this 1918 article states that four girls won a canning contest in Omaha, and would be competing at the state fair. (Canning is the process of saving food for later by sealing it inside cans and jars.) But the headline only tells part of the truth. At the bottom of the article it states that three African American girls won in their own division and would be going to the state fair as well. One of these girls--Inez Battles--was a student at Howard Kennedy. Omaha, like most towns in America, was segregated, which means that Black people and white people were separated. White people were given more privileges than African Americans living in the same town. This is why the white girls and the African American girls were in different groups and why the African American girls were not included in the headline. This article contains the word “colored,” which was commonly used more than 50 years ago to talk about African Americans. Language has changed a lot since then and we no longer use that word. Better words to use are “African American” or “Black.”"
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.3.23.canning.pdf",
            label: "This article from 1919 states that schools all around Omaha had gardening programs, and were having students plant their own gardens at school. One of these schools was Howard Kennedy. At Kennedy, 100 girls and boys would each have a small area where they could do their planting."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.3.30.postercontest.pdf",
            label: "In this clipping from 1919, a poster drawn by Amelia Abendroth, a seventh grader at Howard Kennedy, made the paper! The poster was for a contest and reminds people to be kind to birds."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1919.4.23.postercontest.pdf",
            label: "This clipping from 1919 announces the winners of a poster local poster contest. Howard Kennedy student Amelia Abendroth won second place. Her prize was $5."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1920.10.13.postercontest.pdf",
            label: "This notice from 1920 states that a Kennedy student, Hazel Stevens, had designed the buttons that the Humane Society would be selling to raise money."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1928.4.21.postercontest.pdf",
            label: "This article from 1928 announces the winners of a poster contest. Two of the winners were students at Howard Kennedy named Eva May and Afilee Dotson."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1945.9.28.gardening.pdf",
            label: "This notice from 1945 contains a photograph of Kennedy student Aaron Reed. Aaron won two prizes at the Howard Kennedy Victory Garden Fair and was named head gardener. Victory gardens were planted during World War II by civilians (non-soldiers) so that they could have their own food supply and not have to completely rely on the few options in stores that were available during a war."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1945.11.30.gardening.pdf",
            label: "This clipping from 1945 announces that a student at Howard Kennedy, eleven-year-old Pearl Billingsley, won a bronze medal and $5 at a city-wide contest for her Victory garden. Victory gardens were planted during World War II by civilians (non-soldiers) so that they could have their own food supply and not have to completely rely on the few options in stores that were available during a war."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1950.3.1.quizcontest.pdf",
            label: "This notice from 1950 says that a student at Howard Kennedy named Fred Davis had just won a quiz contest."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1957.3.1.artcontest.pdf",
            label: "This 1957 article is about a seventh grader at Howard Kennedy named Thomas Lewis, Jr. Thomas had just won a prize for a landscape painting."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1963.5.1.ymcaaward.pdf",
            label: "This clipping from 1963 announces that Larry Zachary, a sixth grader at Howard Kennedy, had just been named Y Boy of the Year at the local Y.M.C.A."
        },
        {
            pdf: "Kennedy Archives/AllMedia/1969.10.23.drill.pdf",
            label: "This article from 1969 announces the winners of a contest put on by the Sojourn Drill Team. Carol Stong, a student at Kennedy, won first place in the girls division. Her prize was a bicycle."
        }
    ]
}
