import React from 'react';
import styled from 'styled-components';
import Banner from 'src/Components/Banner/index.js';
import Section from 'src/Components/Layout/Section/index.js';
import Breadcrumbs from 'src/Components/Breadcrumbs/index.js';

const PageContainer = styled.div`
  // margin-top: 30px;

  @media only screen and (min-width: 768px) {
    // margin-top: 60px;
  }
`;

const Heading = styled.h1``;
const SubHeading = styled.h2`
  margin-top: 0;
`;

function AboutPage() {
  return (
    <PageContainer>
      <Banner hideContent={true} backgroundPosition="0% 25%" backgroundImage="/library.jpg" marginLeft="-23" marginRight="-43" marginTop="0"></Banner>
      <Section>
        <Breadcrumbs route="/about"></Breadcrumbs>
        <Heading>About</Heading>
        <SubHeading>Welcome!</SubHeading>
        <p>This website is a place to celebrate the long history of Howard Kennedy Elementary School in North Omaha. Founded as the Omaha View School in 1886 and then rebuilt as Howard Kennedy in 1910, the school has educated students for over 130 years. Kennedy has historically served a largely African American population, and over the last fifty years, the school demographics have included refugee populations originally from areas in both Asia and Africa. The website seeks to tell the story of these students and those who came before them through historic documents and photographs.</p>
        <p>The museums and cultural centers in and around Omaha are excellent resources to find out more about Nebraska’s history, but the exhibits and the stories they tell often center around white populations. Though these stories are important, it is crucial that people of color are also represented in the state’s history. They were in Omaha, working and making significant contributions to their communities. This archive is a small part of the effort to remember these people.</p>
        <p>Representation is particularly important for children coming up in the world. History is so much easier to connect with when we identify in some way with the figures we are learning about. To be able to see oneself in history is a valuable thing. Many of the students at Kennedy might not find the story of a young Warren Buffet relatable--but what about the story of local civil rights activist Charles Washington? Or that of Nebraska’s first Black judge, Elizabeth Pittman? These are tales that need to be told--that could perhaps spark a passion for history in a 10-year-old attending Howard Kennedy.</p>
        <p>The reason that this archive exists online is so that it can be accessible to as many people as possible--at home, at school, or at a local library. Current students, those who work and have worked at the school, alumni, and others who have played a part in this history should be able to see these documents and photographs in one place and look through them as they wish. It’s a history to take pride in and everyone should have access to it.</p>

        <h2>Contents</h2>        
        <p>This website contains historic articles from the Omaha Star and the Omaha World Herald, as well as photographs and other documents provided by former students and staff. There is a glossary of commonly-used terms on the main archive page.</p>

        <p>The archive itself has five sections:</p>
        <ul>
          <li><b>“Old School to New School”</b> tells the story (through historic documents) of how the school has changed over the years--including its name-change from the Omaha View School to Howard Kennedy Elementary School, updates to the building, and the more current revitalization efforts. </li>
          <li><b>“Student Life”</b> includes articles and photographs that paint a picture of life at Kennedy for its students since 1886. There are notices about student clubs, descriptions of classroom activities, reports on special events that took place at the school, and much more.</li>
          <li><b>“Featured Columns”</b> is made up of three different columns in which students from Kennedy and the Omaha View School were featured repeatedly.</li>
          <li><b>“Alumni”</b> contains articles and photographs about former students of Kennedy and their lives after they left the school. These include documents about judges, musicians, activists, veterans, athletes, educators, and other alumni who grew up to make their mark in the world.</li>
          <li><b>“Faculty &amp; Staff”</b> contains articles and photographs about those who have worked at Kennedy--dedicating their lives to education. These documents are a testament to how educators can truly make a difference.</li>
        </ul>
        There is also a portion of the website dedicated to featuring notable figures who did not attend Kennedy but who have made themselves a place in the school’s history in some way. The figures currently featured are Malcolm X, Mildred Brown, and Howard Kennedy.
        This project is ongoing and we will continue to add content over time. Are we missing something that you feel is important? Let us know! Fill out the short form in the “Contact Us” section!
        
        <h2>Who Am I?</h2>
        <p>The contents of this website were composed, organized, and labeled by Elise O’Neil, a graduate student in the Museum and Exhibitions Studies program  at the University of Illinois at Chicago. Known as Miss Elise at Howard Kennedy, she became interested in the school’s history while working there as an educational assistant in the Math Center from 2016 to 2018. She realized that her intervention students were much more interested in word problems about students in historic articles about the school than ones about the U.S. presidents on their plastic coins. Elise wanted to find a way to give the students access to more of these stories.  Longtime kindergarten educational assistant Mrs. Breakfield and art teacher Mr. Pohl had already been gathering primary source materials to put together a history of Kennedy when Elise mentioned her own interest in the matter. This archive is a part of Mrs. Breakfield’s and Mr. Pohl’s original project, in the hopes that it can be a home for materials gathered in the future.</p>
        <p>Though she worked at Howard Kennedy for two years, Elise has never lived in the community surrounding the school. As a white woman, she recognizes that she could never fully understand the experiences of those who live and go to school in the Highlander neighborhood. She has a huge amount of respect for the school and its history, and wishes for that story to be told. She hopes that her own voice is only a small part of the archive and that the materials within it speak for themselves.</p>
        
        <h2>Acknowledgements:</h2>
        <ul>
          <li><b>Mrs. Breakfield</b>, who has served Kennedy tirelessly for decades and has been gathering historic documents about the school for just as long. She is the nucleus of this project and the driving force behind everything that has been accomplished.</li>
          <li><b>Mr. Pohl</b>, whose classroom has served as a library for so many of these documents and photographs for four years. He has spurred this project on from the beginning--scanning countless documents, conducting interviews, and providing much-appreciated ideas and feedback for this website.</li>
          <li><b>Mr. Gunter</b>, without whose support none of this would be possible.</li>
          <li><b>Preston Love, Jr., Robert Blanchard, Roger Sayers, Sheila Matthews, Sheila Dollison, and Warren Taylor</b> who agreed to be interviewed by students on camera about their experiences at Kennedy and in the neighborhood.</li>
          <li><b>Mrs. Brower</b>, who read my initial labels and encouraged my research--as both a boss and a friend.</li>
          <li>My mom, <b>Sara O’Neil</b>, who let me use her library card, got me access to the records on Kennedy at TAC, and checked all my work for typos.</li>
          <li><b>Neal Stanard</b>, who built this website from scratch, listening patiently to all my outrageous demands and making them happen.</li>
          <li><b>The entire Kennedy staff</b>--many of whom have provided direct support for this project--who work harder and with more love than any group of people I have ever met.</li>
          <li>And finally, <b>the students of Howard Kennedy</b>, past and present, to whom this website is dedicated.</li>
        </ul>
      </Section>
    </PageContainer>
  );
}

export default AboutPage;
